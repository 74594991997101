<!-- 评论表单 -->
<template>
  <div class="g-comment">
    <div class="comment-btn" @click="showFn">
      <slot name="btn">
        <g-icon name="iconpinglun" text="评论" size="15px" gap="5px" />
      </slot>
    </div>

    <el-dialog
      :title="from == 'class' ? '培训班评价' : '课程评价'"
      :visible.sync="show"
      width="600px"
      :append-to-body="true"
    >
      <div class="pop-comment">
        <div class="score">
          <g-icon
            :name="form.score >= e.value ? 'iconxing1' : 'iconxing0'"
            :color="form.score >= e.value ? '#f60' : '#999'"
            size="22px"
            v-for="(e, i) in score"
            :key="i"
            @click.native="
              form.score = e.value;
              form.level = e.level;
            "
          />
          <span class="tips">{{ scoreTips }}</span>
        </div>
        <div class="tagRelList">
          <span
            v-for="(e, i) in tagRelList"
            :key="i"
            @click="keyFn(e.id)"
            :class="{
              on: form.tagRelList.findIndex((x) => x.tagId == e.id) > -1,
            }"
            >{{ e.label }}</span
          >
        </div>
        <div class="content">
          <el-input
            v-model="form.comment"
            placeholder="请输入内容"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 8 }"
            minlength="10"
            maxlength="140"
            :show-word-limit="true"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="saveFn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "g-comment",
  props: {
    from: { type: String, default: null },
    id: [Number, String],
  },
  data() {
    return {
      show: false, //评论弹窗开关
      form: {
        score: 5, //1-5分
        level: 1, //评星等级
        tagRelList: [],
        comment: "", //评价内容
      },
      score: [
        { label: "非常差", value: 1, level: "3" },
        { label: "较差", value: 2, level: "3" },
        { label: "一般", value: 3, level: "2" },
        { label: "较好", value: 4, level: "2" },
        { label: "非常棒", value: 5, level: "1" },
      ],
      tagRelList: [],
    };
  },
  computed: {
    ...mapGetters("user", ["access_token", "user", "userInfo"]),
    // 计算当前评分对应提示文字
    scoreTips() {
      let tip = "";
      let i = this.score.findIndex((e) => e.value == this.form.score);
      i = i == -1 ? 4 : i;
      tip = this.score[i].label;
      return tip;
    },
  },
  methods: {
    // 显示弹窗，同时查询并加载关键词数据
    showFn() {
      if (!this.access_token) {
        this.$emit("PopLogin");
        return;
      }
      let ps = { pageNum: 1, pageSize: 1000 };
      let api = "";
      if (this.from == "class") {
        api = "pxbplbq_listApi";
        ps.classId = this.id;
      } else if (this.from == "course") {
        api = "kcplbq_listApi";
        ps.courseId = this.id;
      }
      this.$api[api](ps).then((res) => {
        if (res.code == "200") {
          let list = res.data?.result?.list;
          if (list?.length) {
            this.tagRelList = list.map((e) => {
              return { id: e.id, label: e.tagName, value: 0 };
            });
          }
          this.show = true;
        }
      });
    },
    // 关键词选择事件
    keyFn(id) {
      let i = this.form.tagRelList.findIndex((e) => e.tagId == id);
      if (i == -1) {
        let obj = {
          tagId: id, //标签id
        };
        if (this.from == "course") {
          obj.courseId = this.id; //课程id
        } else if (this.from == "class") {
          obj.classId = this.id; //培训班id
        }
        this.form.tagRelList.push(obj);
      } else {
        this.form.tagRelList.splice(i, 1);
      }
    },

    // 最终保存
    saveFn() {
      this.$emit("save", this.form);
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.comment-btn {
  cursor: pointer;
}

.pop-comment {
  .score {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .g-icon {
      cursor: pointer;
    }
    .tips {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      margin-left: 15px;
    }
  }
  .tagRelList {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
    span {
      display: flex;
      align-items: center;
      height: 24px;
      line-height: 1;
      padding: 0px 10px;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      cursor: pointer;
      font-size: 12px;
      &.on {
        border: 1px solid #ff7046;
        background: #fffbf6;
      }
    }
  }
}
</style>
