<template>
  <div class="g-location">
    <div class="item" v-for="(item, index) in locat" :key="index">
      <span>{{ item.label }}</span>
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-location",
  props: {
    courseType: {
      type: [Number, String],
      default: 1, // 1 是课程 2是直播
    },
  },
  data() {
    return {
      locat: [
        { label: "全部课程", name: "home" },
        { label: "IT互联网", name: "home" },
        { label: "移动开发", name: "home" },
        { label: "人力资源管理", name: "home" },
      ],
    };
  },
  created() {
    this.locat[0].label = `全部${this.courseType == 1 ? "课程" : "直播"}`;
  },
};
</script>

<style lang="less" scoped>
.g-location {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 70px;
  .item {
    display: flex;
    align-items: center;
    gap: 10px;
    i {
      color: #aaa;
    }
    &:last-child i {
      display: none;
    }
  }
}
</style>
