export default {
  parent: "Setting",
  routers: [
    {
      path: "member",
      name: "SetMember",
      component: () => import("@/views/setting/SetMember.vue"),
    },
    {
      path: "company",
      name: "SetCompany",
      component: () => import("@/views/setting/SetCompany.vue"),
    },
    // 部门 员工新版
    {
      path: "staff",
      name: "SetStaff",
      component: () => import("@/views/setting/SetStaff.vue"),
      // component: () => import("@/views/setting/SetStaffShunt.vue"),
    },

    {
      path: "org",
      name: "SetOrg",
      component: () => import("@/views/setting/SetOrg.vue"),
    },
    {
      path: "account",
      name: "SetAccount",
      component: () => import("@/views/setting/SetAccount.vue"),
    },
    {
      path: "asset",
      name: "SetAsset",
      component: () => import("@/views/setting/SetAsset.vue"),
    },
    {
      path: "safe",
      name: "SetSafe",
      component: () => import("@/views/setting/SetSafe.vue"),
    },
    {
      path: "comsafe",
      name: "SetComSafe",
      component: () => import("@/views/setting/SetComSafe.vue"),
    },
    {
      path: "order",
      name: "SetOrder",
      component: () => import("@/views/setting/SetOrder.vue"),
    },
    {
      path: "invoice",
      name: "SetInvoice",
      component: () => import("@/views/setting/SetInvoice.vue"),
    },
  ],
};
