<!--
头部通用筛选框：可控制6部分区域：
1、是否带白色背景（非必有）；
2、是否存在左侧页签切换（非必有）；
3、搜索框（必有）；
4、筛选条件（左右俩插槽、中间下拉筛选组）（非必有）；
5、右侧新增按钮（非必有）
6、右侧插槽（在按钮左侧）（非必有）

<g-search
  v-model="key"                   // 关键词双向绑定
  placeholder="输入关键词"          // 关键词默认提示
  @search="getListFn()"           // 关键词筛选触发事件方法

  :tabs="tabs"                    // 筛选框前的页签切换数据，不存在页签时无需设置
  :tab="1"                        // 默认页签选中值
  @tabClick="tabClickFn"          // 页签点击返回事件

  addText="按钮名称"              // 右侧新增按钮名称，同时作为判断是否存在新增按钮使用，没有按钮无需设置
  @add="isAdd = true"             // 新增按钮触发事件方法
  addTextType="primary"           // 右侧新增按钮的类型 默认是 primary 其他还有 success info warning danger default none   其中 none 是定制的
  addIcon="el-icon-edit"          // 右侧新增按钮的图标  默认是   el-icon-edit  其他还可以是 element-ui 的图标库

  :filter="filterOpt"             // 筛选条件选项
  @filter="filterFn"              // 筛选条件返回方法

  :isBox="true"                   // 是否存在白色背景的盒子
>
  <template slot="prefix">关键词右侧，筛选条件左侧插槽</template>
  <template slot="suffix">筛选条件右侧插槽</template>
  <template slot="right">右侧插槽</template>
</g-search>


// 参数说明
filter=[
  {label:"创建者",mode:"creatorId",opt:[{label:"张三",value:1}]},
  {label:"地点",mode:"cityName",opt:["北京","济南"],defaultText:"不限",value:""},
]
-->
<template>
  <div :class="['g-search', { bgBox: isBox }]">
    <!-- 左 -->
    <div class="left">
      <!-- 页签切换 -->
      <ul class="tabs" v-if="tabs && tabs.length">
        <li
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ on: tabVal == item.value }"
          @click="tabClick(item.value)"
        >
          {{ item.label }}
        </li>
      </ul>
      <!-- 搜索框 -->
      <div class="keywords">
        <div class="keyType" v-if="keyType.length">
          <g-select
            v-model="keyTypeVal"
            placeholder="全部"
            :list="keyType"
            defaultText="全部"
            sty="select"
            class="keyTypeItem"
            @confirm="$emit('keyType', keyTypeVal)"
          />
        </div>
        <el-input
          type="text"
          autocomplete="off"
          :value="value"
          :class="['key', { pl: keyType.length }]"
          :placeholder="placeholder"
          @input="$emit('input', $event)"
          @keyup.enter.native="$emit('search')"
        >
          <em
            slot="append"
            class="searchBtn iconfont iconsousuo"
            @click="$emit('search')"
            title="搜索"
          ></em>
        </el-input>
      </div>
      <!-- 筛选条件 -->
      <div class="filter">
        <!-- 筛选前插槽 -->
        <slot name="prefix" />
        <!-- 下拉筛选条件 -->
        <g-select
          v-for="(item, index) in filter"
          :key="index"
          v-model="item.value"
          :placeholder="item.label"
          :list="item.opt"
          :defaultText="item.defaultText"
          sty="select"
          :isSearch="true"
          class="item"
          @confirm="setValueFn()"
        />
        <!-- 筛选后插槽 -->
        <slot name="suffix" />
      </div>
    </div>
    <!-- 右 -->
    <div class="right">
      <!-- 右侧插槽 -->
      <slot name="right" />
      <el-button
        :type="addTextType"
        :icon="addIcon"
        class="addBtn"
        @click="$emit('add')"
        v-if="addText"
        >{{ addText }}</el-button
      >
    </div>
  </div>
</template>

<script>
import gSelect from "@/components/g-select.vue"; //引入
export default {
  name: "g-search",
  components: { gSelect },
  props: {
    value: String, //关键词，双向绑定
    placeholder: { type: String, default: "输入关键词" }, //关键词默认提示语
    keyType: { type: Array, default: () => [] }, //搜索框下拉
    keyTypeValue: [Number, String],
    defaultKeyType: [String, Number], //搜索框下拉默认值
    tabs: Array, //筛选前面的页签，格式：[{label:'发布中',value:1},{label:'已暂停',value:2},]
    tab: [String, Number],
    isBox: { type: Boolean, default: false }, //筛选框是否存在背景框
    addText: String, //新增按钮文字名称，同时也作为是否存在新增按钮的开关，有值则开，无值则关
    addTextType: {
      type: String,
      default: "primary",
    }, // 新增按钮的类型  element-ui button 组件的类型：primary success info warning danger default
    addIcon: {
      type: String,
      default: "el-icon-edit",
    }, // 新增按钮的图标 默认是  el-icon-edit  还可以是  element-ui 的图标库 也可以是 iconfont 但是要传入  "iconfont iconjia"
    filter: {
      type: Array,
      default: () => [],
    }, //筛选框
  },
  data() {
    return {
      tabVal: this.tab,
      keyTypeVal: null,
    };
  },
  created() {
    this.keyTypeVal = this.keyTypeValue;
  },
  methods: {
    setValueFn() {
      this.$emit("filter", this.filter);
    },
    // 页签切换事件
    tabClick(val) {
      this.tabVal = val;
      this.$emit("tabClick", val);
    },
  },
};
</script>

<style lang="less" scoped>
.g-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px;
    gap: 20px;
    .tabs {
      display: flex;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      overflow: hidden;
      height: 100%;
      li {
        padding: 0 15px;
        min-width: 60px;
        height: 100%;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #888;
        cursor: pointer;
        line-height: 1px;
        background: #fff;
        &:not(:first-child) {
          border-left: 1px solid #e4e4e4;
        }
        &.on {
          background-color: #39c;
          color: #fff;
        }
      }
    }
    /deep/ .keywords {
      width: 260px;
      height: 100%;
      border-radius: 2px;
      border: 1px solid #e4e4e4;
      position: relative;
      .keyType {
        width: 60px;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 2;
        .keyTypeItem {
          width: 100%;
          height: 100%;
          .title.select {
            border-left-width: 0px;
            border-top-width: 0px;
            border-bottom-width: 0px;
            padding: 0 7px;
          }
        }
      }
      .key {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 12px;
        border-radius: 2px;
        position: relative;
        .el-input__inner {
          background-color: #fff;
          border-width: 0px !important;
          width: 100%;
          height: 100%;
          padding: 0 40px 0 10px;
          display: block;
        }
        &.pl {
          .el-input__inner {
            padding-left: 70px;
          }
        }
        .el-input-group__append {
          background-color: transparent;
          border-width: 0px;
          position: absolute;
          top: 1px;
          right: 1px;
          width: 40px;
          height: calc(100% - 2px);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px;
          .searchBtn {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 13px;
          }
        }
      }
    }
    .filter {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      .item {
        display: block;
        height: 100%;
        color: #999;
        min-width: 75px;
        max-width: 150px;
        font-size: inherit;
        cursor: pointer;
        line-height: inherit;
        &.el-date-editor.el-input {
          width: 86px;
          display: block;
          height: 100%;
          font-size: 12px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
        }
        /deep/.el-input {
          display: block;
          height: 100%;
          font-size: inherit;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
        }
        /deep/.el-input__inner {
          display: block;
          text-align: left;
          border-width: 0px;
          height: 100%;
          line-height: inherit !important;
          font-size: inherit;
          padding: 0 30px 0 10px;
          cursor: pointer;
        }
        /deep/.el-select__caret {
          font-size: inherit;
        }
        /deep/.el-input__prefix {
          left: auto;
          right: 4px;
        }
        /deep/.el-input__suffix {
          left: auto;
          right: 4px;
          .el-icon-circle-close {
            background-color: #fff;
          }
          .el-input__icon {
            font-size: 12px;
          }
        }

        /deep/.el-popover__reference-wrapper {
          display: block;
          width: 100%;
          height: 100%;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          .itemSel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 10px;
            color: #999;
            cursor: pointer;
            &.on {
              color: #606266;
            }
            i {
              flex: 1;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      /deep/.el-cascader-panel {
        font-size: 12px;
      }
    }
  }
  .right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px;
    gap: 15px;
    .el-button.addBtn {
      padding: 0px 16px;
      font-size: 12px;
      border-radius: 2px;
      height: 100%;
      min-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      &.el-button--none {
        border: none;
        padding: 0;
        min-width: 0;
        background: none;
      }
    }
    :deep(.addBtn) {
      .iconfont {
        font-size: inherit;
        color: inherit;
      }
    }
  }
  &.bgBox {
    padding: 20px 28px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #e4e4e4;
    .left {
      height: 34px;
      /deep/.el-input__icon {
        line-height: 34px;
      }
    }
    .right {
      height: 34px;
    }
  }
}

.filterMore {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  padding: 0 10px;
}
</style>
