// 讲师接口
export default function (axios) {
  return {
    // 讲师-新增
    js_addApi(params) {
      return axios.post("/cultivate/lecturer/addLecturer", params);
    },
    // 讲师-编辑
    js_editApi(params) {
      return axios.post("/cultivate/lecturer/editLecturer", params);
    },
    // 讲师-删除
    js_delApi(params) {
      return axios.get("/cultivate/lecturer/deleteLecturer", { params });
    },
    // 讲师-列表
    js_listApi(params, type) {
      // type==2外部讲师、默认内部讲师
      let url =
        type == 2
          ? "/cultivate/lecturer/selectPageOut"
          : "/cultivate/lecturer/selectPage";
      return axios.post(url, params);
    },
    // 讲师-列表筛选条件
    js_DepartmentsApi(params) {
      return axios.post(
        "/cultivate/lecturer/selectPositionsAndDepartments",
        params
      );
    },
    // 讲师-详情
    js_detailApi(params) {
      return axios.get("/cultivate/lecturer/selectById", { params });
    },
    // 员工-设为讲师
    js_ygSetLerApi(params) {
      return axios.post("/anon/authorityCtl/setLecturer", params);
    },
    // 员工-取消讲师
    js_ygClearLerApi(params) {
      return axios.post(
        "/anon/authorityCtl/cancelLecturer/" + params.memberId,
        params
      );
    },
  };
}
