export default {
  parent: "train",
  routers: [
    // 培训
    {
      path: "index",
      name: "trainIndex",
      component: () => import("@/views/train/indexView.vue"),
      // redirect: { name: "MyDiary" },
      // meta: { title: "培训" },
    },
    // 学习
    {
      path: "study",
      name: "study",
      component: () => import("@/views/train/study/tabView.vue"),
      // redirect: { name: "MyDiary" },
      // meta: { title: "学习" },
    },
    // 课程
    {
      path: "course",
      name: "course",
      component: () => import("@/views/train/course/indexView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "课程" },
    },
    // 直播
    {
      path: "live",
      name: "live",
      component: () => import("@/views/train/live/indexView.vue"),
      meta: { title: "直播" },
    },
    // 培训班
    {
      path: "class",
      name: "class",
      component: () => import("@/views/train/class/tabView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "班级" },
    },
    // **************************************************************************学员
    // 学员
    {
      path: "student",
      name: "student",
      component: () => import("@/views/train/student/tabView.vue"),
      // meta: { title: "学员" },
    },
    // **************************************************************************讲师
    // 讲师
    {
      path: "lecturer",
      name: "lecturer",
      component: () => import("@/views/train/lecturer/tabView.vue"),
      // redirect: { name: "MyDiary" },
      // meta: { title: "讲师" },
    },
    // **************************************************************************测验
    // 测验
    {
      path: "exam",
      name: "exam",
      component: () => import("@/views/train/exam/indexView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "考评" },
    },
    // 商城
    {
      path: "mall",
      name: "mall",
      component: () => import("@/views/train/mall/tabView.vue"),
      // redirect: { name: "MyDiary" },
      // meta: { title: "商城" },
    },
  ],
};
