import qs from "qs";
export default function (axios) {
  return {
    // 查询测验列表
    listExam(params) {
      return axios.get("/projectBase/selectPage", { params });
    },
    // 新增测验
    postProjectReq(params) {
      return axios.post("/projectBase/updateProject", params);
    },
    // 删除测验
    delExam(data) {
      return axios.post(
        "/projectBase/updateProjectBatch?opeType=" + data.opeType,
        data.list
      );
    },
    // 查询测验详情
    getProjectEdit(params) {
      return axios.get("/projectBase/selectProject", { params });
    },
    // 新增、编辑试卷
    editQuesNair(params) {
      return axios.post("/paperCtl/editPaper", params);
    },
    // 查询问卷详情
    getExamPaper(params) {
      return axios.get("/cProjectExamPaper/selectPaperByPaperId", {
        params,
      });
    },
    // 查询测验下边的试题
    listExamPaperQuestion(params) {
      return axios.get("/cProjectExamPaperQuestion/selectPaperQuestion", {
        params,
      });
    },
    // 新增或者编辑测验下面的问卷试题
    editExamPaperQuestion(params) {
      return axios.post(
        "/cProjectExamPaperQuestion/editExamPaperQuestion ",
        params
      );
    },
    //引入问卷
    updateProjectPaper(params) {
      return axios.post("/project/paper/updateProjectPaper", params);
    },
    //问卷列表
    getProPaperReq(params) {
      return axios.get("/project/paper/selectProjectPaperNose", { params });
    },
    //问卷信息
    selectPaperByPaperId(params) {
      return axios.get("/paperCtl/selectPaperByPaperId", { params });
    },
    //考生查询
    getProStuReq(params) {
      return axios.get("/project/student/selectPage", { params });
    },
    //模板列表
    getProTempReq(params) {
      return axios.get("/project/paper/selectProjectReport", { params });
    },
    //上传模板
    uplodeReportReq(data, params) {
      return axios.post(
        "/project/paper/uploadReport?examId=" +
          params.examId +
          "&projectType=" +
          params.projectType +
          "&requireNeed=" +
          params.requireNeed,
        data
      );
    },
    //删除模板
    delProTempReq(params) {
      return axios.delete("/project/paper/deleteProjectReport", { params });
    },
    //默认科目计分设置
    defaultSubject(params) {
      return axios.post("/paperScoringCtl/defaultSubject", params);
    },
    //默认题型计分设置
    defaulType(params) {
      return axios.post("/paperScoringCtl/defaulType ", params);
    },
    //默认总分计分设置
    defaulSum(params) {
      return axios.post("/paperScoringCtl/defaulSum ", params);
    },
    //默认科目计分设置
    defaultSubjectG(params) {
      return axios.post("/paperScoringCtl/defaultSubject4 ", params);
    },
    //默认题型计分设置
    defaulTypeG(params) {
      return axios.post("/paperScoringCtl/defaulType4 ", params);
    },
    //默认总分计分设置
    defaulSumG(params) {
      return axios.post("/paperScoringCtl/defaulSum4 ", params);
    },
    //问卷试题分页数设置-保存
    savePaperPageReq(params) {
      return axios.post("/paperCtl/editPaperPageCount ", params);
    },
    //批量保存问卷试题样式
    editPaperStyleReq(params) {
      return axios.post("/paperQuestion/editStyle ", params);
    },
    //问卷是否有图片
    paperHasImg(params) {
      return axios.get("/paperCtl/isPaperPic", { params });
    },
    // //静态问卷试题列表
    // getPaperQuesListReq(params) {
    //   return axios.post("/paperQuestion/selectStaticPaperQuestion", params);
    // },
    //分题型获取试题
    selectPaperQuestion(params) {
      return axios.post(
        "/paperMarking/selectPaperQuestion",
        qs.stringify(params)
      );
    },
    //分part获取试题
    selectPaperPartQuestion(params) {
      return axios.post("/paperMarking/selectPaperPartQuestion", params);
    },
    //新增part
    addPaperPart(params) {
      return axios.post("/paperMarking/addPaperPart", params);
    },
    //删除part
    removePaperPart(params) {
      return axios.post("/paperMarking/removePaperPart", params);
    },
    //新增part下试题
    addPaperPartQuestion(data) {
      return axios.post(
        "/paperMarking/addPaperPartQuestion?paperId=" +
          data.paperId +
          "&partId=" +
          data.partId +
          "",
        data.questionList
      );
    },
    //删除part下试题
    removePaperPartQuestion(params) {
      return axios.post("/paperMarking/removePaperPartQuestion", params);
    },
    //删除part下试题
    selectPaperStyleSetByPaperId(params) {
      return axios.post(
        "/paperStyleSetCtl/selectPaperStyleSetByPaperId",
        {},
        { params }
      );
    },
    //静态问卷试题列表
    getPaperQuesListReq(params) {
      return axios.post(
        "/paperQuestion/selectStaticPaperQuestion",
        qs.stringify(params)
      );
    },
    //插入指导语
    insertGuide(params) {
      return axios.post("/paperCtl/insertGuide", qs.stringify(params));
    },
    //静态问卷排序
    updatePaperQuesListReq(params) {
      return axios.post("/paperQuestion/updatePaperQuestionSort", params);
    },
    //问卷预览试题
    getPreviewPaperQuesReq(params) {
      return axios.post("/paperCtl/previewPaper", params);
    },
    //选中、取消必答
    editMustQuestionReq(params) {
      return axios.post("/paperCtl/editMustQuestion", qs.stringify(params));
    },
    //问卷重新排序后同步更改part中的题目号
    updatePartQuestionSort(params) {
      return axios.post("/paperMarking/updatePartQuestionSort", params.list);
    },
    //必填项
    newGetQuesInfoReq(params) {
      return axios.get("/projectExamPaperInfo/selectInfo", { params });
    },
    //问卷重新排序后同步更改part中的题目号
    updatePartQuestionSortPro(params) {
      return axios.post(
        "/paperMarking/updateProjPartQuestionSort",
        params.list
      );
    },
    //新增或者编辑试题
    editQuesReq(params) {
      return axios.post("/questionInfo/editQuestion", params);
    },
    //详细信息
    getQuesDataReq(params) {
      let url = params.paperId
        ? "/paperQuestion/selectPaperQuestionById" //问卷试题详细信息
        : "/questionInfo/selectQuestionById"; //试题详细信息
      return axios.get(url, { params });
    },
    //新增或编辑静态问卷
    editPaperQuesReq(params) {
      return axios.post("/paperQuestion/editPaperQuestionForStatic", params);
    },
    // //批量删除静态问卷下的试题
    // delPaperQuesReq(params) {
    //   return axios.post("/paperQuestion/deletePaperQuestion", params);
    // },
    //新增或者编辑题组
    editQuesGroupReq(params) {
      return axios.post("/questionGroup/editCommonGroupQuestion", params);
    },
    //批量修改矩阵样式试题
    editMatrixQuesReq(params) {
      return axios.post("/paperQuestion/editJuzhenStyle", params);
    },
    //通过问卷id以及矩阵试题样式id查询矩阵样式的详细信息
    getMatrixQuesDataReq(params) {
      return axios.post(
        "/paperQuestion/selectQuestionByPaperQuestionGroupId",
        params
      );
    },
    //试题科目列表
    postQuesSubReq(params) {
      return axios.post("/questionSubject/selectAll", params);
    },
    //试题标签列表
    postQuesTagsReq(params) {
      return axios.post("/questionLabel/selectAll", params);
    },
    //删除或者批量删除试题
    delQuesReq(params) {
      return axios.post("/questionInfo/deleteQuestion", params);
    },
    //新增或者编辑问卷说明
    editQuesExpReq(params) {
      return axios.post(
        "/paperQuestionExplain/editPaperQuestionExplain",
        params
      );
    },
    //批量删除问卷说明
    delQuesExpReq(params) {
      return axios.post("/paperQuestion/deletePaperExplain", params);
    },
    //批量删除问卷信息项
    delQuesInfoReq(params) {
      return axios.post("/paperQuestion/deletePaperInfo", params);
    },
    //批量删除问卷试题以及题组
    delPaperQuesReq(params) {
      return axios.post(
        "/paperQuestion/deletePaperQuestion",
        qs.stringify(params)
      );
    },
    //上移或者下移问卷试题，或者题组
    movePaperQuesReq(params) {
      return axios.post("/paperQuestion/movePaperQuestion", params);
    },
    //上移或者下移问卷说明
    movePaperExplainReq(params) {
      return axios.post("/paperQuestion/movePaperExplain", params);
    },
    //拖拽排序问卷试题，或者题组
    insertPaperQuestionReq(params) {
      return axios.post("/paperQuestion/insertPaperQuestion", params);
    },
    //拖拽排序问卷说明
    dragPaperExplainReq(params) {
      return axios.post("/paperQuestion/dragPaperExplain", params);
    },
    //问卷信息
    selectPaperByPaperIdNose(params) {
      return axios.get("/paperCtl/selectPaperByPaperIdNose", { params });
    },
    //监考
    addAdminInvigilatorReq(params) {
      return axios.post("/anon/projectInvigilate/addAdminInvigilator", params);
    },
    //删除问卷
    changePaperStatusByPaperId(params) {
      return axios.post("/paperCtl/deletePaperByPaperId", params);
    },
    //删除问卷套餐
    updatePaperComboStatus(params) {
      return axios.get("/project/paper/updatePaperComboStatus", params);
    },
    //问卷预览试题
    getPreviewPaperQuesReqNose(params) {
      return axios.post("/paperCtl/previewPaperNose", qs.stringify(params));
    },
    //查询问卷外观
    selectPaperStyleSetByPaperIdNose(params) {
      return axios.post(
        "/paperStyleSetCtl/selectPaperStyleSetByPaperIdNose",
        qs.stringify(params)
      );
    },
    //查询难度
    getDiffListReq(params) {
      return axios.post("/questionDiffcult/selectAll", params);
    },
    //查询问卷下的答案
    getPaperScoreReq(params) {
      return axios.post(
        "/paperQuestionScore/selectByPaperIdNose",
        qs.stringify(params)
      );
    },
    //编辑答案设置
    editPaperScoreReq(params) {
      return axios.post("/paperQuestionScore/editPaperQuestionScore", params);
    },
    //查询动态问卷试题
    getDynamicQuesList(params) {
      return axios.post("/paperQuestion/selectDongtaiPaperQuestion", params);
    },
    //查询答案设置
    getDtPaperScoreReq(params) {
      return axios.post("/paperQuestionScore/selectQuestionScore", params);
    },
    //动态问卷设置试题分数
    editDtPaperScoreReq(params) {
      return axios.post("/paperQuestionScore/editDongtaiScore", params);
    },
    //查询计分项列表
    getPaperScoringList(params) {
      return axios.get("/paperScoringCtl/selectPaperScoringList", { params });
    },
    //查询计分项列表
    getPaperIdList(params) {
      return axios.get("/paperScoringCtl/selectQuesIdAndSort", { params });
    },
    //删除计分信息项
    updatePaperScoringSetStatus(params) {
      return axios.post("/paperScoringCtl/updatePaperScoringSetStatus", params);
    },
    //动态规则查询试题数量
    getDynamicQuesList(params) {
      return axios.post("/paperQuestion/selectDongtaiPaperQuestion", params);
    },
    //删除考生
    deleteProjectStudent(params) {
      return axios.delete("/project/student/deleteProjectStudent", params);
    },
    //考生查看答题情况
    getselectAnswerListReq(params) {
      return axios.get("/project/score/selectAnswerList", { params });
    },
    //更新考生缓存
    addStudentToRedisNoseReq(params) {
      return axios.post("/exam/addStudentToRedisNose", qs.stringify(params));
    },
    //新增、编辑考生
    updateProjectStudent(params) {
      return axios.post("/project/student/updateProjectStudent", params);
    },
    //编辑考场
    postEditPlaceReq(params) {
      return axios.post(
        "/project/student/editPlace?examId=" +
          params.examId +
          "&projectType=" +
          params.projectType +
          "",
        params.placeList
      );
    },
    //获取考场列表
    postSelectPlaceListReq(params) {
      return axios.post(
        "/project/student/selectPlaceList",
        qs.stringify(params)
      );
    },
    //删除考场
    postDeletePlaceReq(params) {
      return axios.post("/project/student/deletePlace", params);
    },
    //查询问卷分享的参数
    getShareInfoReq(params) {
      return axios.post("/paperCtl/selectProjectWxShareNose", qs.stringify(params));
    },
    //保存微信分享个性化设置
    saveShareInfoReq(params) {
      return axios.post("/paperCtl/editWxShare", params);
    },
    //成绩列表
    getProScoreReq(params) {
      return axios.get("/project/score/selectPage", { params });
    },
    //删除
    delProScoreDelReq(params) {
      return axios.delete("/project/score/deleteProjectStudentScore", params);
    },
    //详情页问卷列表
    getProScorePaperListReq(params) {
      return axios.get("/project/score/selectPaperList", { params });
    },
    //获取考生监控记录
    selectMonitorRecordReq(params) {
      return axios.post("/anon/projectInvigilate/selectMonitorRecord", params);
    },
    //详情
    getScoreDetailReq(params) {
      return axios.get("/project/score/selectScoreDetail", { params });
    },
    //获取考试信息
    getExamNoseReq(params) {
      return axios.get("/exam/selectByIdNose", { params });
    },
    //获取登录信息
    getLoginNoseReq(params) {
      return axios.post("/exam/loginNose", params);
    },
    //发验证码
    getExamCodeReq(params) {
      return axios.get("/exam/verifyCodeNose", { params });
    },
    //查询考生必填项信息
    selectProjectReq(params) {
      return axios.get("/exam/selectProjectPaperInfo", { params });
    },
    //详情页-问卷列表
    getProScorePaperListNoseReq(params) {
      return axios.get("/project/score/selectPaperListNose", { params });
    },
    //考生成绩查询
    getScoreDetailNoseReq(params) {
      return axios.get("/project/score/selectScoreDetailNose", { params });
    },
    //判断考生是否已生成完毕
    getReportStatusNoseReq(params) {
      return axios.get("/project/score/getReportStatusNose", { params });
    },
    //查询考生问卷列表
    getQuesListReq(params) {
      return axios.get("/exam/selectPaperList", { params });
    },
    //答题初始密封条
    getPaperQuesPageReq(params) {
      return axios.get("/exam/selectPaperQuestionPage", { params });
    },
    //开始答题，查询问卷试题
    getPaperQuesReq(params) {
      return axios.get("/exam/selectQuestionList", { params });
    },
    //查询在线考生基本信息
    getStuInfoReq(params) {
      return axios.get("/exam/selectStudentInfo", { params });
    },
    //提交答案
    saveAnswersReq(params, data) {
      const queryString = new URLSearchParams(data).toString();
      return axios.post("/exam/saveAnswers?" + queryString, params);
    },
    //查询学生问卷答案
    getAnswersReq(params) {
      return axios.get("/exam/selectAnswers", { params });
    },
    //考生开启视频监控获取sig，各种形式项目登录通用
    getGenSigReq(params) {
      return axios.post("/player/genSig", qs.stringify(params));
    },
    //根据域名获取参数(wx_appid_kf为微信分享所需参数，缺省时，使用前端现用值为默认值)
    getParamNoseReq(params) {
      return axios.post("/player/getParamNose", params);
    },
    //考生成功加入房间开启视频通话时调用
    joinRoomReq(params) {
      return axios.post("/player/joinRoom", params);
    },
    //获取系统参数通用方法
    selectParams(params) {
      return axios.get("/exam/getDictNose", { params });
    },
    //查询考生必填项信息-新
    newGetRequiredReq(type) {
      let url =
        type == 2
          ? "/investigation/selectProjRequired" //调查
          : type == 4
          ? "/enroll/selectProjRequired" //报名
          : type == 5
          ? "/vote/selectProjRequired" //投票
          : "/exam/selectProjRequired"; //考试
      return axios.get(url);
    },
    //提交考生必填项信息-新
    newPostRequiredReq(params) {
      return axios.post("/exam/saveProjRequired", params);
    },
    //项目数据
    getProDataReq(params) {
      return axios.get("/project/data/selectPage", { params });
    },
    //监控数据查询
    getInvigilateReq(params) {
      return axios.get("/project/data/selectInvigilatePage", { params });
    },
    //查询单个考生
    getInvigilateByIdReq(params) {
      return axios.get("/project/data/selectInvigilateByStudentId", { params });
    },
    //导出答题数据
    getExportDataReq(params) {
      return axios.get("/project/data/exportData", { params });
    },
    //获取导出报告的url
    getProjectDatatUrlNoseReq(params) {
      return axios.get("/project/data/getProjectDatatUrlNose", { params });
    },
    //将试题添加到问卷
    addQuestionForPaperReq(params) {
      return axios.post(
        "/questionInfo/addQuestionForPaper?paperId=" + params.paperId,
        params.questionInfoList
      );
    },
    //阅卷
    getProPaperAnswerReq(params) {
      return axios.get("/project/data/selectPaperAnswerData", { params });
    },
    //阅卷时查询动态问卷试题列表
    getDtPaperQuesListReq(params) {
      return axios.post("/project/data/paper/previewPaper", params);
    },
    //提交阅卷数据
    postMarkingReq(params) {
      return axios.post("/project/data/Marking", params);
    },
    //阅卷-查询动态问卷下面的试题答案设置
    selectByQuestionIdsReq(params) {
      return axios.post("/paperQuestionScore/selectByQuestionIds", params);
    },
    //查询有无答题记录
    paperHasAsReq(params) {
      return axios.get("/project/score/selectHasAnswerByPaperIdNose", {
        params,
      });
    },
    //项目统计查询图标
    selectChartsList(params) {
      return axios.post(
        "/project/student/selectChartsList",
        qs.stringify(params)
      );
    },
    //更新成绩
    updateScoreReq(params) {
      return axios.post("/anon/score/updateScore", params);
    },
    //更新报告
    updateReportReq(params) {
      return axios.psot("/anon/score/updateReport", params);
    },
    //查询任务进度
    postUpdateScoreTaskReq(params) {
      return axios.post("/anon/score/selectTask", params);
    },
    //项目成绩-导出报告
    exportProjectReportReq(params) {
      return axios.get("/fileMake/exportProjectReport", { params });
    },
    //获取导出报告的url
    getProjectReportReq(params) {
      return axios.get("/fileMake/getExportUrl", { params });
    },
    //分题型获取未分part的题
    selectPaperPartQuestionPro(params) {
      return axios.post(
        "/paperMarking/selectProjPaperPartQuestion",
        qs.stringify(params)
      );
    },
    //新增part
    addPaperPartPro(params) {
      return axios.post("/paperMarking/addProjPaperPart", qs.stringify(params));
    },
    //删除part
    removePaperPartPro(params) {
      return axios.post("/paperMarking/removeProjPaperPart", params);
    },
    //新增part下试题
    addPaperPartQuestionPro(params) {
      return axios.post(
        "/paperMarking/addProjPaperPartQuestion?paperId=" +
          params.paperId +
          "&partId=" +
          params.partId +
          "&examId=" +
          params.examId +
          "&projectType=" +
          params.projectType +
          "&paperTypeId=" +
          params.paperTypeId +
          "",
        params.questionList
      );
    },
    //删除part下试题
    removePaperPartQuestionPro(params) {
      return axios.post("/paperMarking/removeProjPaperPartQuestion", params);
    },
    //查询问卷列表
    getQuesList(params) {
      return axios.post("/paperCtl/selectPaperCount", qs.stringify(params));
    },
    //长链接转短网址
    getShorlUrlReq(params) {
      return axios.post("/dwz/getShorlUrl", params);
    },
    //项目列表查询
    selectProjectLabelListPage(params) {
      return axios.get(
        "/paramImportCtl/anon/selectProjectLabelListPage",
        params
      );
    },
    //阅卷老师查询
    getTeacherSelectPageReq(params) {
      return axios.get("/cTeacher/selectPageNew", { params });
    },
    //新增或编辑阅卷老师接口
    postEditTeacherReq(params) {
      return axios.post("/cTeacher/editTeacher", params);
    },
    //批量删除阅卷老师
    postDeleteTeacherReq(params) {
      return axios.post(
        "/cTeacher/deleteTeacher?teacherIds=" + params.teacherIds + "",
        params
      );
    },
    //新增阅卷老师part
    addPaperTeacherPartReq(params) {
      return axios.post(
        "/cTeacher/addProjPaperTeacherPart?teacherId=" + params.teacherId,
        params.partList
      );
    },
    //获取问卷part试题
    selectPaperPartQuestionReq(params) {
      return axios.post(
        "/cTeacher/selectProjPaperPartQuestionNose",
        qs.stringify(params)
      );
    },
    //获取问卷老师已分配part
    selectPaperTeacherPartReq(params) {
      return axios.post(
        "/cTeacher/selectProjPaperTeacherPartNose?teacherId=" +
          params.teacherId,
        params
      );
    },
    //项目阅卷设置
    getMarkingConfigReq(params) {
      return axios.post(
        "/cTeacher/getMarkingConfig?examId=" +
          params.examId +
          "&projectType=" +
          params.projectType,
        params
      );
    },
    //项目阅卷设置
    editMarkingConfigReq(params) {
      return axios.post("/cTeacher/editMarkingConfig", params);
    },
    //外观查询
    postProStyleSetReq(params) {
      return axios.post("/projectStyleSet/selectProjectStyleSetNose", qs.stringify(params));
    },
  };
}
