export default {
  namespaced: true,
  local: true,
  state: {
    goodsType: [], //商品类目
  },
  mutations: {
    params(state, info) {
      if (info.goodsType) state.goodsType = info.goodsType;
    },
  },
};
