<template>
  <g-selectset
    v-model="paperId"
    placeholder="选择练习"
    :options="list"
    :class="className"
    :placement="placement"
    @input="practiceReplaceFn"
    @save="practiceSaveFn"
    @del="practiceDelFn"
  >
    <g-icon
      name="iconqiehuanzhanghao"
      color="#999"
      text="切换练习"
      gap="5px"
      v-if="className == 'mySwitch'"
    />
    <el-button type="primary" size="medium" v-if="className == 'myBtn'"
      >选择已有练习</el-button
    >
  </g-selectset>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    coursewareId: [Number, String], //课件id
    className: String,
    placement: String, //弹窗出现位置：top/bottom
  },
  data() {
    return {
      paperId: null,
      list: [],
    };
  },
  watch: {
    paperId: {
      handler(v) {
        this.$emit("input", v);
      },
    },
  },
  created() {
    this.getListFn();
  },
  methods: {
    // 获取练习列表（是练习列表，不是试题列表）
    getListFn() {
      let ps = { pageNum: 1, pageSize: 100 };
      this.$api.kcwj_listApi(ps).then((res) => {
        if (res.code == "200") {
          this.list = res.data.result?.list.map((e) => {
            return { label: e.paperName, value: e.paperId };
          });
          this.$set(this, "paperId", this.value);
        }
      });
    },
    // 新增、编辑
    practiceSaveFn(e) {
      console.log(e, "=eee");
      let ps = { paperName: e.name };
      if (e.id) {
        ps.paperId = e.id;
      }
      this.$api.kcwj_editApi(ps).then((res) => {
        if (res.code == "200") {
          this.getListFn();
        }
      });
    },
    // 删除
    practiceDelFn(e) {
      let ps = { paperId: e };
      this.$api.kcwj_delApi(ps).then((res) => {
        if (res.code == "200") {
          this.getListFn();
        }
      });
    },

    // 切换练习
    practiceReplaceFn() {
      let ps = { coursewareId: this.coursewareId, paperId: this.paperId };
      this.$api.kcwj_replaceApi(ps);
    },
  },
};
</script>

<style lang="less" scoped>
.g-selectset {
  &.myBtn,
  &.mySwitch {
    /deep/.selectTitle {
      padding: 0px;
    }
    /deep/.select-box {
      width: 260px;
    }
  }
}
</style>