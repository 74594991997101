export default {
  parent: "study",
  routers: [
    // 学习-学习中
    {
      path: "list/ing",
      name: "xx-ing",
      component: () => import("@/views/train/study/indexView.vue"),
      meta: { title: "学习" },
    },
    // 学习-未学习
    {
      path: "list/no",
      name: "xx-no",
      component: () => import("@/views/train/study/indexView.vue"),
      meta: { title: "学习" },
    },
    // 学习-已学习
    {
      path: "list/end",
      name: "xx-end",
      component: () => import("@/views/train/study/indexView.vue"),
      meta: { title: "学习" },
    },
    // 学习-全部
    {
      path: "list/all",
      name: "xx-all",
      component: () => import("@/views/train/study/indexView.vue"),
      meta: { title: "学习" },
    },
  ],
};
