// 个人中心
export default {
  routers: [

    // // 个人会员中心登录页面
    // {
    //   path: "/personal/login",
    //   name: "p_login",
    //   component: () => import("@/views/personal/loginView.vue"),
    // },

    // 首页
    {
      path: "/personal",
      name: "p_home",
      component: () => import("@/views/personal/studyView.vue"),
      // meta: { title: "个人中心" },
    },
    // 我的学习
    {
      path: "/personal/study",
      name: "p_study",
      component: () => import("@/views/personal/studyView.vue"),
      // meta: { title: "个人中心-我的课程" },
    },
    // 我的订单
    {
      path: "/personal/order",
      name: "p_order",
      component: () => import("@/views/personal/orderView.vue"),
      // meta: { title: "个人中心-我的订单" },
    },
    // 我的收藏
    {
      path: "/personal/collect",
      name: "p_collect",
      component: () => import("@/views/personal/collectView.vue"),
      // meta: { title: "个人中心-我的收藏" },
    },
    // 我的笔记
    {
      path: "/personal/note",
      name: "p_note",
      component: () => import("@/views/personal/noteView.vue"),
      // meta: { title: "个人中心-我的笔记" },
    },
    // 个人信息-会员
    {
      path: "/personal/member",
      name: "p_member",
      component: () => import("@/views/personal/member.vue"),
      // meta: { title: "个人中心-会员" },
    },
    // 账户
    {
      path: "/personal/account",
      name: "p_account",
      component: () => import("@/views/personal/account.vue"),
      // meta: { title: "个人中心-账户" },
    },
    // 账号与安全
    {
      path: "/personal/safe",
      name: "p_safe",
      component: () => import("@/views/personal/safeView.vue"),
      // meta: { title: "个人中心-安全" },
    },
    // 发票
    {
      path: "/personal/invoice",
      name: "p_invoice",
      component: () => import("@/views/personal/invoice.vue"),
      // meta: { title: "个人中心-发票" },
    },
    // 订单
    {
      path: "/personal/order2",
      name: "p_order2",
      component: () => import("@/views/personal/order.vue"),
      // meta: { title: "个人中心-我的订单" },
    },

  ],
};
