<!--注释：
职位弹窗
-->
<template>
  <div class="form-warpper no-select">
    <h2>分享链接</h2>
    <div class="share-box" style="padding: 30px 30px">
      <!--分享链接-->
      <div class="url">
        <div class="clearfix">
          <input type="text" readonly v-model="url" class="text" />
          <button
            class="btnnull"
            v-clipboard:copy="url"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            复制
          </button>
          <a :href="url" target="_blank"
            ><button class="btnnull">打开</button></a
          >
        </div>
        <div class="clearfix mt25">
          <button class="btnout" @click="$commons.downLoadQrFn('qrCode')">
            下载二维码
          </button>
          <button class="btnout">二维码海报</button>
        </div>
      </div>
      <!--分享渠道-->
      <div class="orient" v-if="quShare != 'account'">
        <em class="iconfont iconweixin"></em>
        <em class="iconfont iconxinlang"></em>
        <em class="iconfont iconqq"></em>
      </div>
      <!--二维码-->
      <div class="ewm">
        <div class="tac">
          <p style="margin-top: -8px">微信扫码</p>
          <div id="qrCode" class="qr-code"></div>
        </div>
      </div>
    </div>

    <h2 class="mt30">分享设置</h2>
    <div class="share-box">
      <div class="set">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="0px"
          class="demo-ruleForm"
        >
          <div class="title">分享图片</div>
          <el-form-item class="share-pic clearfix">
            <div class="i-cm">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            </div>
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="uploadfile"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只支持JPG和PNG格式，建议尺寸80*80px，不超过4M
              </div>
            </el-upload>
          </el-form-item>
          <div class="title mt30">分享标题</div>
          <el-form-item prop="title">
            <el-input
              type="text"
              placeholder="输入分享标题"
              v-model="title"
              maxlength="30"
              show-word-limit
              class="text maxlength"
            ></el-input>
          </el-form-item>
          <div class="title mt30">分享说明</div>
          <el-form-item prop="title">
            <el-input
              autosize
              type="textarea"
              placeholder="输入分享说明"
              v-model="content"
              maxlength="50"
              show-word-limit
              class="textarea maxlength"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              class="btnsave mr15"
              style="width: 90px; height: 34px"
              >保存</el-button
            >
            <el-button
              @click="resetForm('ruleForm')"
              class="btnnull"
              style="width: 90px; height: 34px"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="view">
        <h3>分享到朋友圈</h3>
        <div class="share flex">
          <div class="i-cm">
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          </div>
          <div class="font">
            <div class="title">{{ title }}</div>
          </div>
        </div>
        <h3 class="mt15">发送微信好友</h3>
        <div class="share">
          <div class="title">{{ title }}</div>
          <div class="flex mt10">
            <p class="f12 c8 font">{{ content }}</p>
            <div class="i-cm">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            </div>
          </div>
        </div>
        <h3 class="mt15">分享到QQ好友</h3>
        <div class="share">
          <div class="title">{{ title }}</div>
          <div class="flex mt10">
            <p class="f12 c8 font">{{ content }}</p>
            <div class="i-cm">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-share",
  props: {
    quShare: String, //来源
    shareData: Object, //要分享的数据
  },
  data() {
    return {
      ruleForm: {},
      rules: {},
      url: "https://dwz.cn/9oI5mguL", //分享路径
      imageUrl: require("@/assets/image/logo/logo.png"), //分享图片
      title: "培训！", //分享标题
      content: "",
      // "自动同步各招聘渠道投递的简历，并区别归类于招聘职位，节省时间，提高筛选、管理效率！", //分享内容
    };
  },
  //页面初始加载
  created() {
    let that = this;
    this.$nextTick(() => {
      that.$commons.creatQrFn(this.url, "qrCode", 66);
    }),
      this.quUpdate();
  },
  methods: {
    quUpdate() {
      if (this.quShare == "account") {
        this.title = "公司名称";
        this.content = "这里是企业官网简介";
        this.imageUrl = require("@/assets/image/logo/logo.png");
      } else if (this.quShare == "job") {
        console.log(this.shareData, "详情");
        this.title = this.shareData.jobName;
        this.content = this.shareData.jobDuty;
        this.imageUrl = require("@/assets/image/logo/logo.png");
      }
    },

    closePop() {
      //关闭弹窗的传参
      this.$emit("closePop");
    },
    //上传图片
    uploadfile(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
    },
    //上传限制
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG或PNG 格式!");
      }
      return isJPG || isPNG;
    },
    //复制链接
    onCopy() {
      this.$message.success("内容已复制到剪切板！");
    },
    onError() {
      this.$message.error("抱歉，复制失败！");
    },
    submitForm() {},
  },
};
</script>

<style lang="less" scoped>
.mt30 {
  margin-top: 30px;
}
.text {
  height: 34px;
  line-height: 34px;
}
.form-warpper {
  h2 {
    font-size: 18px;
    color: #666;
    font-weight: 400;
    line-height: 24px;
  }
  .share-box {
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .url {
      flex: 1;
      .text {
        float: left;
        width: 65%;
        height: 34px;
        line-height: 34px;
      }
      .btnnull {
        float: left;
        width: 15%;
        height: 34px;
        border: 1px solid #39c;
        color: #39c;
        margin-left: 2%;
      }
      .btnout {
        float: left;
        width: auto;
        height: 34px;
        margin-right: 2%;
        padding: 0 15px;
        background-color: #fff;
      }
    }
    .orient {
      width: 25%;
      display: flex;
      justify-content: center;
      em {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        margin-right: 12px;
      }
      em.iconweixin {
        background-color: #00d700;
      }
      em.iconxinlang {
        background-color: #e6162d;
      }
      em.iconqq {
        background-color: #0075c2;
      }
    }
    .ewm {
      width: 16%;
      text-align: right;
      border-left: 1px dashed #e4e4e4;
      div {
        width: 76px;
        float: right;
        p {
          display: block;
          margin: 0px 0px 5px 0;
        }
        .qr-code {
          width: 76px;
          height: 76px;
          padding: 5px;
          border: 1px solid #e4e4e4;
          border-radius: 3px;
          overflow: hidden;
        }
      }
    }

    .set {
      width: 60%;
      padding-right: 30px;
      .title {
        margin-bottom: 8px;
      }
      .i-cm {
        float: left;
        width: 80px;
        height: 80px;
        border: 1px solid #e4e4e4;
        border-radius: 2px;
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .upload-demo {
        float: left;
      }
    }
    .view {
      width: 40%;
      padding: 25px 30px 30px;
      background-color: #ebedf1;
      h3 {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
      }
      .share {
        background: #fff;
        padding: 15px;
        color: #666;
        border-radius: 2px;
        .i-cm {
          float: left;
          width: 50px;
          height: 50px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .font {
          width: calc(100% - 65px);
        }
        p {
          line-height: 160%;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }
}
</style>

<style>
.set .el-form-item__content {
  line-height: 34px;
}
.set .share-pic .avatar-uploader .el-upload {
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.set .share-pic .el-button--primary {
  height: 30px;
  width: 80px;
  border-color: #e4e4e4 !important;
  background-color: #fff !important;
  color: #666;
  border-radius: 2px;
  font-size: 14px;
  padding: 0px;
}
.set .el-upload__tip {
  margin-top: 30px;
  line-height: 16px;
}
.maxlength .el-input__inner {
  padding-right: 50px;
}
.textarea .el-textarea__inner {
  padding-right: 50px;
}
.textarea .el-input__count {
  bottom: 1px !important;
  line-height: 30px;
}
.i-cm {
  padding: 5px;
}
</style>
