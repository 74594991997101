// 学员接口
export default function (axios) {
  return {
    // 学员-新增
    xy_addApi(params) {
      return axios.post("/cultivate/trainee/addTrainee", params);
    },
    // 学员-删除
    xy_delApi(params) {
      return axios.get("/cultivate/trainee/deleteTrainee", { params });
    },
    // 学员-列表
    xy_listApi(params) {
      return axios.post("/cultivate/trainee/selectPage", params);
    },
    // 学员-详情（除用户信息之外的数据）
    xy_detail(params) {
      return axios.post(
        `/cultivate/class/selectClassInfoByTrainee?traineeId=${params.traineeId}`
      );
    },
    // 给学员添加必修课
    xy_addClass(params) {
      return axios.post(`/cultivate/class/addClass`, params);
    },
  };
}
