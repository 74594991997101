import md5 from "@/assets/utils/md5";
import common from "@/assets/utils/commom";

// 员工相关接口
export default function (axios) {
  return {
    // 获取所有职位
    getStaffPost(name = "", sign) {
      // let url = `/${sign === "hro" ? axios.urlHro : axios.urlHrm
      //   }/compStaffMember/selectPost`;
      let url = sign === "hro" ? axios.urlHro : axios.urlHrm;
      url = url + "/compStaffMember/selectPost";
      return axios.get(url, { params: { name } });
    },

    // /compStaffMember/selectStaffMemberFilter 成员筛选
    selectStaffMemberFilter(params, controller) {
      let signal =
        controller && controller.signal ? { signal: controller.signal } : {};
      return axios.get(
        `/compStaffMember/selectStaffMemberFilter`,
        {
          params,
        },
        signal
      );
    },

    // 新增员工
    addStaff(info) {
      info.securityLevel = common.checkPwd(info.pwd || "");
      if (info.userId) {
        if (info.pwd) {
          info.passWord = md5.md5(info.pwd);
        }
        delete info.pwd;
        return axios.post("/compStaffMember/updateCompStaff", info);
      } else {
        if (info.pwd) {
          info.passWord = md5.md5(info.pwd);
        } else {
          info.passWord = md5.md5("123456");
        }
        delete info.pwd;
        return axios.post("/compStaffMember/insertCompStaffInfo", info);
      }
    },
    // 员工减员
    reductionStaff(params) {
      let url = "/compStaffMember/delStaff";
      return axios.post(url, params);
    },
    // 修改员工状态
    updateStaffState(userId, status) {
      let url = "/compStaffMember/updateCompStaffMemberStatus";
      return axios.post(url, {}, { params: { userId, status } });
    },
    // 关闭员工登录功能
    closeStaff(userIds) {
      let url = "/compStaffMember/updateCompStaffMemberLonginStatus";
      return axios.post(url, {}, { params: { userIds, loginStatus: 2 } });
    },


    // --------------------------------------------------------------------入职管理
    // 入职管理列表
    entryListPage(params) {
      return axios.post("/entryManagement/selectRecordListPage", params);
    },
    // 办理入职
    handleEntry(params) {
      let url = "/entryManagement/handleEntry";
      return axios.post(url, params);
    },
    // 确认入职
    confirmEntry(params) {
      let url = "/entryManagement/confirmEntry";
      return axios.post(url, params);
    },
    // 修改待入职员工信息
    updateEntry(params) {
      let url = "/entryManagement/updateEntry";
      return axios.post(url, params);
    },
    // 发送入职登记表
    sendFormUrl(params) {
      let url = "/entryManagement/sendFormUrl";
      return axios.post(url, {}, { params });
    },
    // 取消入职
    cancelEntry(params) {
      let url = "/entryManagement/cancelEntry";
      return axios.post(url, {}, { params });
    },

    // 查看待入职员工详情
    entryDetail(params) {
      let url = "/entryManagement/selectById";
      return axios.get(url, { params });
    },
    // 待入职数据统计
    entryCount(params) {
      return axios.get("/entryManagement/getCount", { params });
    },

    // --------------------------------------------------------------------转正管理
    // 确认转正
    confirmOfficial(params) {
      let url = "/officialManagement/confirmOfficial";
      return axios.post(url, params);
    },
    // 调整转正日期
    updateOfficialDate(params) {
      let url = "/officialManagement/updateOfficialDate";
      return axios.post(url, params);
    },
    // 待转正数据统计
    getToCount(params) {
      let url = "/officialManagement/getCount";
      return axios.get(url, { params });
    },

    // --------------------------------------------------------------------离职管理
    // 确认离职
    confirmDimission(params) {
      let url = "/dimissionManagement/confirmDimission";
      return axios.post(url, params);
    },
    // 取消离职
    cancelDimission(params) {
      let url = "/dimissionManagement/cancelDimission";
      return axios.post(url, {}, { params });
    },
    // 调整离职信息
    updateDimissionDate(params) {
      let url = "/dimissionManagement/updateDimissionDate";
      return axios.post(url, params);
    },
    // 待离职、已离职数据统计
    getOutCount(params) {
      let url = "/dimissionManagement/getCount";
      return axios.get(url, { params });
    },

    // --------------------------------------------------------------------异动记录
    // 入职记录列表
    ydList(params, tab) {
      let url = "";
      if (tab == 1) {
        url = "/entryManagement/selectRecordListPage"; //入职记录
      } else if (tab == 2) {
        url = "/officialManagement/selectRecordListPage"; //转正记录
      } else if (tab == 3) {
        url = "/dimissionManagement/selecteMobilizeRecordList"; //调动记录
      } else if (tab == 4) {
        url = "/dimissionManagement/selectRecordListPage"; //离职记录
      }
      return axios.post(url, params);
    },

    // --------------------------------------------------------------------我的助手
    // 我的助手
    myAssistantList(params, type) {
      let url = "";
      if (type == 1) {
        url = "/entryManagement/selectRecordByUser"; //入职记录
      } else if (type == 2) {
        url = "/officialManagement/selectRecordByUser"; //转正记录
      } else if (type == 3) {
        url = "/dimissionManagement/selecteMyMobilizeRecord"; //调动记录
      } else if (type == 4) {
        url = "/dimissionManagement/selectRecordByUser"; //离职记录
      }
      return axios.post(url, params);
    },



    //--------------------------------------------------------------------设置：成员
    // // 设为管理员
    // setAdmin(params) {
    //   let url = "/compStaffMember/setStaffAdmin"; //新增
    //   return axios.post(url, {}, { params });
    // },

    //--------------------------------------------------------------------设置：职位职级

    // 新增/编辑员工的备注与描述
    editStaffRemarksApi(params) {
      return axios.post("/remarks/editStaffRemarks", params);
    },
    // 人员的备注和描述回显
    getStaffContractApi(params) {
      return axios.post("/remarks/getStaffContract", params);
    },
    // 查询有员工的部门 / 供应商
    selectExistStaffApi() {
      return axios.get("/remarks/selectExistStaff");
    },

  };
}
