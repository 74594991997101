export default function (axios) {
  return {
    /*发票相关*/
    // 公司/员工
    //    查询所有（发票列表接口）
    postInvoiceListReq(params) {
      return axios.post("/cReceipt/selectAll", params);
    },
    // 查询发票信息列表
    selectInvoiceList(params) {
      return axios.post("/cReceiptTemplate/selectAll", params);
    },
    // 新增发票信息
    insertInvoice(params) {
      return axios.post("/cReceiptTemplate", params);
    },
    // 修改
    updateInvoice(params) {
      return axios.post("/cReceiptTemplate/update", params);
    },
    // 删除
    deleteInvoice(params) {
      return axios.delete("/cReceiptTemplate", params);
    },
    // 	查询发票部分信息
    selectTotalInvoice(params) {
      return axios.post("/cReceipt/selectTotal", params);
    },
    // 个人
    // 查询发票列表/查询单个发票信息
    selectPersonInvoiceList(params) {
      return axios.get("/cReceiptInfoCtl/selectReceiptInfoPerson", params);
    },
    // 新增发票信息
    insertPersonInvoice(params) {
      return axios.get("/cReceiptInfoCtl/insertReceiptInfoPerson", params);
    },
    // 修改
    updatePersonInvoice(params) {
      return axios.get("/cReceiptInfoCtl/updateReceiptInfoPerson", params);
    },
    // 删除
    deletePersonInvoice(params) {
      return axios.get("/cReceiptInfoCtl/updateReceiptStatusPerson", params);
    },

  }
}