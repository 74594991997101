// 个人中心
export default function (axios) {
  return {
    // 班级（我的学习、我的订单、我的收藏共用）
    p_classListApi(params) {
      return axios.post("/cultivate/trainingClass/selectClassByTrainee", params);
    },
    // 我的收藏-课程列表
    p_courseListApi(params) {
      return axios.post("/collect/selectCollectList", params);
    },
  }
}