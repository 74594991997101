<!-- 视频播放组件
  内包含：视频播放、章节信息、随堂笔记、收藏、分享、评论
  除视频播放外，其他信息均可控制是否显示
-->
<template>
  <div class="g-play" :style="'height:' + h + 'px'">
    <div class="play-main">
      <!-- 直播信息 -->
      <!-- <div class="play-info">
        <img :src="detail.coverUrl" v-if="detail.coverUrl" />
        <div class="info">
          <div class="title">{{ detail.liveTitle }}</div>
          <div class="lecturer">讲师：{{ detail.lecturerNames || "--" }}</div>
          <div class="times">
            开讲时间：{{ detail.validStartTime?.substring(0, 16) }} 至
            {{ detail.validEndTime?.substring(0, 16) }}
          </div>
          <div class="status">
            <span class="state s1" v-if="detail.status == 2">未开播</span>
            <span class="state s2" v-if="detail.status == 1">直播中</span>
            <span class="state s3" v-if="detail.status == 3">已结束</span>
          </div>
        </div>
      </div> -->
      <!-- 视频播放器 -->
      <div class="play-video">
        <!-- 因为直播现在还没有回放 暂时 直播中才显示播放 -->
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :options="playerOptions"
          @timeupdate="onTimeUpdate"
          @play="onPlayerPlay"
          v-if="play.isLoad && detail.status == 1"
        ></video-player>
        <div class="video-empty" v-else>
          <img :src="detail.coverUrl" v-if="detail.coverUrl" />
          <div class="info">
            <div class="title">{{ detail.liveTitle }}</div>
            <div class="lecturer">讲师：{{ detail.lecturerNames || "--" }}</div>
            <div class="times">
              开讲时间：{{ detail.validStartTime?.substring(0, 16) }} 至
              {{ detail.validEndTime?.substring(0, 16) }}
            </div>
            <div class="status">
              <span class="state s1" v-if="detail.status == 2">未开播</span>
              <span class="state s2" v-if="detail.status == 1">直播中</span>
              <span class="state s3" v-if="detail.status == 3">已结束</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 播放器底部功能 -->
      <div class="play-foot">
        <div class="item" v-if="!isCreate">
          <!-- iconxing1 -->
          <g-icon
            :name="collect?.collectType == 1 ? 'iconxing1' : 'iconxing0'"
            :color="collect?.collectType == 1 ? '#ff9f00' : ''"
            text="收藏"
            gap="5px"
            @click.native="collectFn"
          />
          <!-- 是商品才可以分享   postMall 还未返回 TODO-->
          <g-icon
            name="iconout"
            text="分享"
            size="13px"
            gap="5px"
            @click.native="shareFn"
          />
          <g-comment from="course" :id="liveId" @save="commentFn" />
          <!-- 企业没有学习栏目  保持统一 -->
          <g-icon
            :name="collect?.studyType == 1 ? 'iconxuexi3' : 'iconxuexi2'"
            :color="collect?.studyType == 1 ? '#39c' : ''"
            text="学习"
            gap="5px"
            @click.native="studyFn"
            v-if="user.userType != 2"
          />
        </div>
        <div class="item item-kaibo" v-if="showOpenLive">
          <el-button type="primary" round class="kaibo" @click="debutFn"
            >开播</el-button
          >
        </div>
      </div>
    </div>

    <!-- 右侧信息 -->
    <div class="play-side" v-if="modu.show">
      <g-icon
        name="iconcuo"
        size="20"
        color="#999"
        class="close"
        @click="modu.show = false"
      />
      <g-tab v-model="modu.value" :tabs="tabs" />
      <!-- 弹幕模块 -->
      <div class="module danmu" v-show="modu.value == 'danmu'">
        <div class="list no-scroll" ref="danmu">
          <div class="item" v-for="(item, index) in danmu.list" :key="index">
            <!-- 消息 -->
            <template v-if="item.msgType == 1">
              <g-avatar
                :username="item.fromUser.nickname"
                :size="26"
                class="face"
              />
              <div class="info">
                <div class="name">{{ item.fromUser.nickname }}</div>
                <div class="text">{{ item.msgContent }}</div>
              </div>
            </template>
            <!-- 加入直播间 -->
            <template v-if="item.msgType == 2">
              <div class="tips">
                <span>{{ item.fromUser.nickname }}来了</span>
              </div>
            </template>
          </div>
        </div>
        <div class="form" v-if="isLogin">
          <el-input
            v-model="danmu.text"
            placeholder="请输入内容"
            class="text"
            @keyup.enter.native="sendDanmu"
          />
          <el-button type="primary" @click="sendDanmu">发送</el-button>
        </div>
        <div class="form" v-else>
          <span>登录后方可聊天</span>
        </div>
      </div>
      <!-- 笔记模块 -->
      <div class="module note" v-show="modu.value == 'note'">
        <div ref="note" class="list no-scroll">
          <div class="item" v-for="(item, index) in modu.noteList" :key="index">
            <div class="time">
              <em></em><span>{{ item.createTime }}</span>
            </div>
            <div class="value" v-html="$utils.toHtml(item.noteContent)"></div>
          </div>
        </div>
        <div class="noteForm">
          <div class="form" v-if="note.show">
            <el-input
              v-model="note.text"
              placeholder="请输入内容"
              type="textarea"
              :rows="4"
            >
            </el-input>
            <div class="noteBtn">
              <el-button type="primary" @click="writeNoteFn">提交</el-button>
              <el-button @click="note.show = false">取消</el-button>
            </div>
          </div>
          <g-icon
            name="iconadd"
            class="btn"
            size="14px"
            @click.native="note.show = true"
            title="写笔记"
            v-else
          />
        </div>
      </div>
    </div>

    <ShareDialog
      :detail="detail"
      :liveId="liveId"
      :visible.sync="isShowShareDialog"
    ></ShareDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShareDialog from "@/views/train/live/components/Share";
export default {
  name: "g-live",
  components: {
    ShareDialog,
  },
  props: {
    h: { type: Number, default: 500 }, //高度
    isCreate: { type: Boolean, default: false }, //是否创建者
    isLecturer: { type: Boolean, default: false }, //是否讲师
    liveId: [Number, String], // 直播id
    detail: Object,
    showOpenLive: {
      type: Boolean,
      default: false,
    }, // 是否显示开播按钮
  },
  data() {
    return {
      collect: {},

      play: {
        isLoad: false,
        duration: 0, //总时长
        currentTime: 0, //已播放时长
        recordId: null,
        isRecordIng: false, //是否正在执行
        i: 0,
      },
      modu: {
        show: true, //是否显示
        value: "danmu", //当前显示哪个模块
      },
      note: {
        show: false, //写课堂笔记开关
        text: "", //课堂笔记表单输入
      },
      danmu: {
        list: [], //弹幕列表数据
        text: "", //弹幕文本
      },

      livePusher: null,

      socket: null,
      fromUser: null,

      isPlaying: false, // 是否正在播放直播

      isShowShareDialog: false, // 是否显示分享弹窗
    };
  },
  computed: {
    ...mapGetters("user", ["user", "userInfo"]),
    playerOptions() {
      let obj = {};
      if (this.play.isLoad) {
        let playUrl = this.detail.playUrl?.replace("http://", "https://");
        obj = {
          //视频url设置,直播流为例
          sources: [
            {
              src: playUrl || "", //视频文件地址
              // type: "video/mp4", //视频类型，这里可以不写，如果写一定要写对，否则会无法播放
            },
          ],
          // 其他设置项
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //提示信息
          autoplay: false, //是否自动播放
          controls: true, //是否显示控制栏
          poster: this.detail.coverUrl, //视频封面
          height: this.isCreate ? this.h - 20 : this.h - 70, //高度
          playbackRates: [0.5, 1, 1.5, 2, 2.5, 3], //视频播放速度
          language: "zh-CN", //设置播放器语言
          // fluid: true,
        };
      }
      return obj;
    },
    tabs() {
      let arr = [{ label: "聊天", value: "danmu" }];
      // if (!this.isCreate && this.id) {
      //   arr.push({ label: "课堂笔记", value: "note" });
      // }
      return arr;
    },
    isLogin() {
      return this.user.access_token;
    }, // 是否登录了
  },
  watch: {
    "modu.value": {
      handler(v) {
        if (v == "note") {
          this.getNoteListFn();
          this.toBottom();
        } else if (v == "danmu") {
          this.getDanmuFn();
          this.toBottom();
        }
      },
      deep: true,
    },
  },
  created() {
    this.getDanmuFn();
    this.toBottom();

    this.creatScript();

    this.initWebsocket();

    this.getCollectFn();
  },
  methods: {
    loadScript(url) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.onload = () => resolve(script);
        script.onerror = () =>
          reject(new Error(`Script load error for ${url}`));
        document.body.appendChild(script);
      });
    },
    async creatScript() {
      try {
        await this.loadScript(
          "https://imgcache.qq.com/open/qcloud/live/webrtc/js/TXLivePusher-1.0.0.min.js"
        );
        console.log("Script loaded successfully");
        this.initLivePusher();
        this.play.isLoad = true;
      } catch (error) {
        console.error("Script loading failed:", error);
      }
    },
    initLivePusher() {
      this.livePusher = new TXLivePusher();
    },

    initWebsocket() {
      let that = this;
      let token = this.$store.getters["user/access_token"];
      let userName = this.$store.getters["user/userName"];
      let userInfo = this.$store.getters["user/userInfo"];
      let userSign = this.$store.getters["user/userSign"];
      let avatarUrl = "";
      if (userSign == "company") {
        avatarUrl = userInfo.compLogWebAddr || "";
      } else {
        avatarUrl = userInfo.staffPhotoWebAddr || "";
        if (avatarUrl[0] == "#") {
          avatarUrl = "";
        }
      }
      this.fromUser = {
        avatarUrl,
        nickname: userName,
        userId: this.$store.getters["user/userId"],
      };
      let path = `anon/api/websocket/${this.detail.liveId}/${userName}?token=${token}&avatarUrl=${avatarUrl}`;
      this.socket = new WebSocket(
        // `wss://careerstudy.cn:9059/${encodeURI(path)}`
        `wss://careerstudy.cn/${encodeURI(path)}`
      );
      this.socket.onopen = (e) => {
        console.log("[open] Connection established", e);
      };
      this.socket.onclose = (e) => {
        console.log("WebSocket is closed now", e);
      };
      this.socket.onmessage = function (events) {
        console.log(`[message] Data received from server: ${events.data}`);
        that.danmu.list.push(JSON.parse(events.data));
      };
      this.socket.onerror = function (error) {
        console.log(`[error] ${error.message}`);
      };
    },
    closeWebsocket() {
      if (!this.socket) {
        return false;
      }
      this.socket.close(1000, "Work complete");
    },

    // 获取收藏数据
    getCollectFn() {
      // console.log("开发中...");
      let ps = {
        coursewareId: -1,
        courseId: this.detail.liveId,
        courseType: 2, // 课程类型：1 课程；2 直播课程
      };
      this.$api.kc_getcollectApi(ps).then((res) => {
        if (res.code == "200") {
          this.collect = res.data;
        }
      });
    },
    // 收藏
    collectFn() {
      let ps = {
        collectId: this.collect?.collectId || null, //	收藏主键 新增不传/修改传
        collectType: 1, //	1 收藏 2 取消收藏
        courseId: this.detail.liveId, //	课程id 新增传
        // coursewareId: this.id, //	课件id 新增传
        courseType: 2, // 课程类型：1 课程；2 直播课程
      };
      this.$api.kc_collectApi(ps).then((res) => {
        if (res.code == "200") {
          this.getCollectFn();
        }
      });
    },

    // 学习
    studyFn() {
      let ps = {
        collect: {
          collectId: this.collect?.studyId || null, //	收藏主键 新增不传/修改传
          collectType: this.collect?.studyType == 1 ? 0 : 1, //	1 学习 0 取消学习
          courseId: this.detail.liveId, //	课程id
          courseType: 2, // 课程类型：1 课程；2 直播课程
          favorites: 1,
        },
      };
      this.$api.kc_addStudyApi(ps).then((res) => {
        if (res.code == "200") {
          this.getCollectFn();
        }
      });
    },

    // 分享
    shareFn() {
      this.isShowShareDialog = true;
      // this.$message("分享");
    },
    // 评论
    commentFn(e) {
      let ps = {
        commentScore: e.score, //	string	评论分数
        commentText: e.comment, //	string	评论内容
        commentType: e.level, //	integer	1:好评；2:中评；3:差评
        liveId: this.liveId, //	integer	课程编码 新增传
        courseId: this.liveId,
        courseType: 2, //课程类型：1 课程；2 直播课程
        tagRelList: e.tagRelList, //评论标签
      };
      this.$api.kcpl_editApi(ps).then((res) => {
        if (res.code == "200") {
          this.$message.success("评论成功");
          this.$emit("comment");
        }
      });

      // this.$refs.comment.getListFn();
    },

    getNoteListFn() {
      let ps = {
        pageNum: 1,
        pageSize: 10000000,
        coursewareId: this.id,
      };
      this.$api.kc_noteListApi(ps).then((res) => {
        if (res.code == "200") {
          this.modu.noteList = res.data.result.list;
          this.toBottom();
        }
      });
    },
    writeNoteFn() {
      let ps = {
        liveId: this.liveId, //	integer	课程id 新增传
        coursewareId: this.id, //	integer	课件id 新增传
        noteContent: this.note.text, //	string	笔记内容
      };
      this.$api.kc_noteAddApi(ps).then((res) => {
        if (res.code == "200") {
          this.note.show = false;
          this.note.text = "";
          this.getNoteListFn();
        }
      });
    },
    // 弹幕、笔记跳转到底部
    toBottom() {
      this.$nextTick(() => {
        const container = this.$refs[this.modu.value];
        container.scrollTop = container.scrollHeight;
      });
    },
    // 获取弹幕数据
    getDanmuFn() {
      // for (let i = 0; i < 15; i++) {
      //   let arr = [
      //     "好！好！好！好！",
      //     "鼓掌",
      //     "《钢铁是怎样炼成的》",
      //     "没听清，麻烦再说一下",
      //     "1111",
      //     "vue怎么引入组件的？",
      //   ];
      //   let num = Math.floor(Math.random() * arr.length);
      //   let obj = {
      //     name: "张三" + i,
      //     text: arr[num],
      //   };
      //   this.danmu.list.push(obj);
      // }
      console.log(this.danmu.list, "=this.danmu.list");
    },
    // 发送弹幕
    sendDanmu() {
      let item = {
        fromUser: this.fromUser,
        liveRoomId: this.detail.liveId,
        msgContent: this.danmu.text,
        msgType: "1",
        time: Date.now(),
      };
      this.socket.send(JSON.stringify(item));
      // this.danmu.list.push(item);
      this.danmu.text = "";
      this.toBottom();
    },
    // 立即直播，跳转到直播页面
    debutFn() {
      let ps = { id: this.liveId };
      this.$utils.toPage("liveDebut", ps, false, true);
    },

    onTimeUpdate(e) {
      console.log("onTimeUpdate", e);
    },
    onPlayerPlay(e) {
      console.log("onPlayerPlay", e);
    },
  },
  beforeDestroy() {
    this.closeWebsocket();
  },
};
</script>

<style lang="less" scoped>
.g-play {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  background-color: #1b2128;
  border-radius: 2px;
  overflow: hidden;
}
.play-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 0px;
  height: 100%;
  background-color: #000;
  .play-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: -5px;
    height: 50px;
    // background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    padding: 0 20px;
    position: relative;
    z-index: 2;
    .name {
      max-width: 50%;
      font-weight: 700;
    }
    .info {
      flex: 1;
      width: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      color: #999;
      font-size: 12px;
      i {
        color: #39c;
      }
      .g-icon {
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }

  .play-info {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .info {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 25px; // 30px
      line-height: 2;
      font-size: 16px;
      color: #fff;
      .title {
        font-size: 24px;
      }
    }
  }
  .play-video {
    flex: 1;
    height: 0px;
    width: 100%;
    overflow: hidden;
    padding: 10px 10px;
    .video-player {
      width: 100%;
      height: 100%;
      object-fit: contain;
      /deep/.vjs-error .vjs-error-display:before {
        display: none;
      }
    }
    .video-empty {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
      }
      .info {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        line-height: 2;
        font-size: 16px;
        color: #fff;
        .title {
          font-size: 24px;
        }
      }
    }
  }
  .play-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: -5px;
    height: 50px;
    background-color: rgba(27, 33, 40, 0.5);
    color: #fff;
    padding: 0 20px;
    position: relative;
    z-index: 2;
    .item {
      display: flex;
      align-items: center;
      gap: 20px;
      &.item-kaibo {
        margin-left: auto;
      }
      .g-icon {
        line-height: 1;
        cursor: pointer;
      }
      /deep/.fan {
        .iconfont {
          transform: rotateX(180deg);
        }
      }
      .kaibo {
        padding: 0 0px;
        width: 80px;
        height: 26px;
        font-size: 12px;
      }
    }
  }
}
.play-side {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  // background-color: #fff;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 10px);
    height: 12px;
    background: linear-gradient(
      0deg,
      #1b2128 11.61%,
      rgba(27, 33, 40, 0) 99.59%
    );
    pointer-events: none;
  }
  .close {
    position: absolute;
    top: 18px;
    right: 10px;
    cursor: pointer;
  }
  /deep/.g-tab {
    color: #a1a9b2;
    padding: 5px 30px 0;
    font-size: 16px;
    border-bottom: 1px solid #14171a;
    span.on {
      color: #c0c5cc !important;
    }
  }
  .module {
    flex: 1;
    width: 100%;
    height: 0;
    padding: 20px 30px 12px 30px; // 20px 30px 20px 30px
    overflow: hidden;
    overflow-y: auto;
    color: #999;

    &.note {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .list {
        margin: -10px;
        padding: 10px;
        flex: 1;
        height: 0px;
        overflow: hidden;
        overflow-y: auto;
        .item {
          // border: 1px solid #111;
          border-radius: 2px;
          padding: 10px 0px;
          line-height: 180%;
          font-size: 12px;
          &:not(:last-child) {
            border-bottom: 1px solid #111;
          }
          .time {
            position: relative;
            em {
              position: absolute;
              top: 9px;
              left: -10px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #999;
            }
            span {
              color: #667280;
            }
          }
          .value {
            color: #a1a9b2;
          }
        }
      }
      .noteForm {
        height: 0px;
        position: relative;
        margin: 0 -10px;
        .form {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          z-index: 2;
          background-color: #1b2128;

          /deep/.el-textarea {
            font-size: 12px;
          }
          .noteBtn {
            margin-top: 10px;
            .el-button {
              width: auto;
              height: 26px;
              padding: 0 20px;
              font-size: 12px;
            }
          }
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          line-height: 1;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.5);
          color: #fff;
          position: absolute;
          right: -10px;
          bottom: -5px;
          z-index: 1;
          cursor: pointer;
          &:hover {
            background-color: rgba(51, 153, 204, 1);
          }
        }
      }
    }
    &.danmu {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .list {
        margin: 0px -10px 0 -10px;
        padding: 10px;
        flex: 1;
        height: 0px;
        overflow: hidden;
        overflow-y: auto;
        .item {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          border-radius: 2px;
          padding: 10px 0px;
          line-height: 180%;
          font-size: 12px;
          .info {
            .text {
              background-color: rgba(255, 255, 255, 0.1);
              padding: 2px 5px;
              font-size: 12px;
              color: #fff;
              border-radius: 2px;
            }
          }
        }
      }
      .form {
        position: relative;
        /deep/.text.el-input {
          width: 100%;
          height: 28px;
          border-radius: 16px;
          .el-input__inner {
            border-radius: 16px;
            font-size: 12px;
          }
        }
        .el-button {
          position: absolute;
          right: 0px;
          top: 0px;
          z-index: 1;
          width: 60px;
          height: 28px;
          border-radius: 16px;
          padding: 0px;
          font-size: 12px;
        }
      }
    }
  }
}

/deep/.vjs-custom-skin > .video-js {
  height: 100%;
}
</style>
