// 商城接口
export default function (axios) {
  return {
    // *****************************************************
    // 商城
    // *****************************************************
    // 商城-课程
    sc_courseListApi(params) {
      return axios.post("/cultivate/mall/selectMallCourseNose", params);
    },
    // 商城-直播
    sc_liveListApi(params) {
      return axios.post("/cultivate/mall/selectMallLiveNose", params);
    },
    // 商城-培训班
    sc_classListApi(params) {
      return axios.post("/cultivate/mall/selectMallPaperNose", params);
    },

    sc_listApi(params) {
      return axios.post("/cultivate/mall/selectMallNose", params)
    },






    // *****************************************************
    // 支付(已被匡俊奇弃用，采用了其他接口)
    // *****************************************************
    // 支付-获取当前账号余额(已弃用)
    pay_balanceApi(params) {
      return axios.post("/cultivate/mall/getAvailableAmount", params)
    },
    // 支付-生成订单号(已弃用)
    pay_orderApi(params) {
      return axios.post("/cultivate/mall/buyMall", params)
    },
    // 支付-提交订单(已弃用)
    pay_submitApi(params) {
      return axios.post("/cultivate/mall/submitOrder", params)
    },

  };
}
