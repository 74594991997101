export default {
  parent: "lecturer",
  routers: [
    // 讲师-内部的
    {
      path: "in",
      name: "js-in",
      component: () => import("@/views/train/lecturer/indexView.vue"),
      meta: { title: "讲师" },
    },
    // 讲师-外部的
    {
      path: "out",
      name: "js-out",
      component: () => import("@/views/train/lecturer/indexView.vue"),
      meta: { title: "讲师" },
    },
  ],
};
