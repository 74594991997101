<!--
  头像添加及回显：有头像显示头像，没头像利用名称和背景色回显
-->
<template>
  <div class="g-avatar">
    <div
      :title="username"
      :class="[
        'avatarBox no-select',
        { auto: username && !imgArr.length && !isComp },
        { cur: isUpload },
        shape,
      ]"
      :style="styleVar"
      @click="isUpload ? $refs.fileInput.click() : ''"
    >
      <img :src="imgArr[0].url" v-if="imgArr.length" class="img" />
      <span
        v-if="!isComp && username && !imgArr.length"
        :class="size && size <= 30 ? 'avatar-name' : ''"
      >
        {{ name }}
      </span>
      <slot v-if="!username && !imgArr.length && !isComp">
        <g-icon :name="icon" v-if="icon" />
        <g-icon name="iconface" v-else class="icon" />
      </slot>
      <img
        :src="avatar"
        alt=""
        v-if="!imgArr.length && isComp"
        class="imgComp"
      />
    </div>
    <input
      class="file-input"
      ref="fileInput"
      type="file"
      accept="image/*"
      @change="onInputFile"
    />
    <g-icon
      :name="sex == 1 ? 'iconnan' : 'iconnv'"
      :class="['sex', sex == 1 ? 'nan' : 'nv']"
      v-if="sex > -1"
    />
  </div>
</template>
<script>
export default {
  name: "g-avatar",
  props: {
    isComp: Boolean,
    urls: [String, Array],
    size: { type: [Number, String], default: 100 },
    auto: { type: Boolean, default: false },
    avatarColor: String,
    icon: String,
    type: { type: String, default: "comp_img" },
    username: String,
    multiple: { type: Boolean, default: false },
    shape: { type: String, default: "circle" }, //形状：square：正方形，circle圆形（默认）
    isUpload: { type: Boolean, default: false }, //是否允许上传
    sex: { type: [Number, String], default: -1 }, //是否显示性别,-1不显示，1男2女
  },
  data() {
    return {
      // 自动选择颜色
      colors: [
        "#ff9e48",
        "#fa9dc6",
        "#ff8a73",
        "#9e9dfb",
        "#fc5d7d",
        "#63d19f",
        "#ca9dfa",
        "#62b5d1",
        "#d6be5c",
        "#87cefa",
      ],
      index: 0,
      imgArr: [],
      color: "",
      avatar: require("@/assets/image/error/compony.png"),
    };
  },
  watch: {
    urls: {
      handler() {
        let urls = this.urls;
        this.imgArr = [];
        if (urls && urls.indexOf("#") !== 0) {
          urls = [this.urls];
          if (urls && urls.length) {
            for (let item of urls) {
              if (item) {
                this.imgArr.push({ url: item });
              }
            }
          }
        }
        if (urls && urls.indexOf("#") == 0) {
          this.color = this.urls;
        }
      },
      immediate: true,
    },
  },
  created() {
    if (!this.urls) {
      this.color = this.colors[Math.floor(Math.random() * this.colors.length)];
      if (!this.isUpload) {
        this.color = "#39c";
      }
      this.$emit("changeColor", this.color);
    }
  },
  computed: {
    name() {
      let str = this.username;
      if (!this.username || typeof this.username !== "string") {
        throw "头像生成失败，名字错误";
      }
      if (this.username.length > 2) {
        str = this.username.substring(this.username.length - 2);
      }
      return str;
    },
    // 自定义头像尺寸及背景颜色
    styleVar() {
      return {
        "--box-size": this.size + "px",
        "--box-font": 0.28 * this.size + "px",
        "--icon-size": 0.4 * this.size + "px",
        "--box-backgroundColor": this.color,
      };
    },
  },
  methods: {
    onInputFile(e) {
      let files = e.currentTarget.files;
      if (files.length) {
        if (this.multiple) {
          for (let img of files) {
            this.imgArr.push({ url: URL.createObjectURL(img), file: img });
          }
        } else {
          let img = files[0];
          this.imgArr = [{ url: URL.createObjectURL(img), file: img }];
        }
        this.$emit("change");
        if (this.auto) {
          this.submit().then((urls) => {
            if (this.multiple) {
              this.$emit("update:urls", urls);
            } else {
              this.$emit("update:urls", urls?.[0] || "");
            }
          });
        }
      }
    },
    submit(action) {
      let url = action || "/ossApi/putObjects";
      if (url) {
        return this.upload(url);
      }
      return Promise.resolve([]);
    },
    upload(url) {
      let files = [];
      for (let item of this.imgArr) {
        if (item.file) files.push(item.file);
      }
      if (!files.length) {
        return Promise.resolve(this.imgArr.map((item) => item.url));
      }
      return this.$axios
        .upload(url, { file: files }, { params: { fileDirType: this.type } })
        .then((res) => {
          let urls = [];
          for (let item of this.imgArr) {
            if (item.file) {
              let url = res.data[item.file.name];
              if (url) {
                item.file.url = url;
                urls.push(url);
              }
            }
          }
          return urls;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.g-avatar {
  position: relative;
}
.cur {
  cursor: pointer;
}
.avatarBox {
  width: var(--box-size);
  height: var(--box-size);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid @border;
  overflow: hidden;
  font-size: var(--box-font);
  color: #8c939d;
  position: relative;
  &.square {
    border-radius: 2px;
  }
  &.circle {
    border-radius: 50%;
  }
  &.auto {
    border: 0;
    background-color: var(--box-backgroundColor);
    color: #ffffff;
  }
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .imgComp {
    width: 110%;
    height: 110%;
    object-fit: cover;
  }
  .icon {
    font-size: var(--icon-size);
  }
  .avatar-name {
    font-size: 12px;
    transform: scale(0.75);
    white-space: nowrap;
  }
}
.file-input {
  top: 0;
  width: 0px;
  height: 0px;
  position: absolute;
  visibility: hidden;
}

.sex {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  color: #39c;
  background-color: #fff;
  border-radius: 50%;
  &.nan {
    color: rgba(51, 153, 204, 1);
  }
  &.nv {
    color: rgba(255, 102, 0, 1);
  }
}
</style>
