//let env=process.env.NODE_ENV
//正式环境
// export const apiUrl = "http://8.142.175.96:8899";
export const apiUrl = "//recruit.staffcloud.cn/api"; //user

let env = "production";
// if (location.hostname.indexOf("ceshi.asktrue") > -1) {
//   env = "development";
// }
export const apiUrl1 = "https://careerstudy.cn/user"; //user

export const apiUrl11 =
  env == "production" ? "//exam.staffcloud.cn/answer" : "//39.101.162.160:8088"; //面试视频时所用

export const apiUrl12 = "https://user.staffcloud.cn"; //阿仕图-考试
