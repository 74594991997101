import md5 from "@/assets/utils/md5";
import common from "@/assets/utils/commom";

// 员工相关接口
export default function (axios) {
  return {


    // 获取员工档案列表
    getStaffArchives(params, sign) {
      let url = "/staffArchiveCtl/getStaffArchivePage";
      url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
      return axios.post(url, params);
    },
    // 获取档案详情
    getArchivesInfo(params, sign, nose) {
      if (params.archivesId) {
        let url = "/staffArchiveCtl/getStaffArchive";
        url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
        url = nose ? url + "Nose" : url;
        return axios.get(url, { params });
      } else {
        let url = "/staffArchiveCtl/getMyStaffArchive";
        url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
        return axios.get(url);
      }
    },
    // 添加/修改员工档案
    addStaffArchives(params, sign, nose) {
      let url = sign === "hro" ? axios.urlHro : axios.urlHrm;
      if (params.id) {
        url += "/staffArchiveCtl/updateStaffArchive";
      } else {
        url += "/staffArchiveCtl/insertStaffArchive";
      }
      url = nose ? url + "Nose" : url;

      if (params.password) {
        params.password = md5.md5(params.password);
      }
      return axios.post(url, params);
    },
    // 删除员工档案
    removeStaffArchives(params, sign) {
      let url = "/staffArchiveCtl/delStaffArchive";
      url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
      return axios.post(url, {}, { params });
    },

    // 统计
    // staffSelectCount(params, sign) {
    //   let url = "/staffArchiveCtl/selectCount";
    //   url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
    //   return axios.get(url, { params });
    // },
    staffSelectCount(params, sign) {
      let url = "/staffArchiveCtl/selectCount";
      url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
      return axios.post(url, params);
    },
    // 批量导入员工档案
    importStaff(params, sign) {
      let url = "/staffArchiveCtl/importStaff";
      url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
      return axios.get(url, { params });
    },

    // 邀请完善信息
    sendPerfect(params) {
      let url = "/internal/staffArchiveCtl/sendPerfect";
      return axios.post(url, params);
    },

    // 发起离职
    initiateDimission(params, sign) {
      let url = "/staffArchiveCtl/initiateDimission";
      url = "/" + (sign === "hro" ? axios.urlHrm : axios.urlHrm) + url;
      return axios.post(url, params);
    },
    // 发起调动
    initiateMobilize(params) {
      let url = "/staffArchiveCtl/initiateMobilize";
      url = "/" + "internal" + url;
      return axios.post(url, params);
    },

    // 花名册全部导出
    exportDataFn(params, sign) {
      let url = "/staffArchiveCtl/exportData";
      url = "/" + (sign === "hro" ? axios.urlHrm : axios.urlHrm) + url;
      let ps = [];
      for (let key of Object.keys(params)) {
        ps.push(key + "=" + params[key]);
      }
      return url + "?" + ps.join("&");
      // this.$utils.exportFile(url + "?" + ps.join("&"), "花名册", ".xlsx");
      // return axios.get(url, { params });
    },


    //--------------------------------------------------------------------花名册表单部分字段自定义选项（职位、职级）
    // 新增选项
    autoOptAdd(params) {
      let url = "/internal/staffArchiveCtl/insertData"; //新增
      if (params.id) {
        url = "/internal/staffArchiveCtl/editData"; //修改
      }
      return axios.post(url, params);
    },
    // 删除选项
    autoOptDel(params, sign) {
      let url = "/staffArchiveCtl/delData";
      url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
      return axios.get(url, { params });
    },
    // 查询选项
    autoOptList(params) {
      let url = "/internal/staffArchiveCtl/selectListNose";
      return axios.get(url, { params });
    },

    //--------------------------------------------------------------------花名册独立查询接口
    getStaffArchiveNose(params, sign) {
      let url = "/staffArchiveCtl/getStaffArchiveNose";
      url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
      return axios.get(url, { params });
    },

    //--------------------------------------------------------------------设置：职位职级
    // 职位-增、改
    savePosiApi(params) {
      let url = "/internal/staffArchiveCtl/insertStaffPosition"; //新增
      if (params.id) {
        url = "/internal/staffArchiveCtl/editStaffPosition"; //修改
      }
      return axios.post(url, params);
    },
    // 职位-删
    delPosiApi(params) {
      let url = "/internal/staffArchiveCtl/delStaffPosition";
      return axios.get(url, { params });
    },
    // 职位-查
    getPosiApi(params) {
      let url = "/internal/staffArchiveCtl/findStaffPositionNose";
      return axios.get(url, { params });
    },
    // 职级-增、改
    savePostApi(params) {
      let url = "/internal/staffArchiveCtl/insertStaffRank"; //新增
      if (params.id) {
        url = "/internal/staffArchiveCtl/editStaffRank"; //修改
      }
      return axios.post(url, params);
    },
    // 职级-删
    delPostApi(params) {
      let url = "/internal/staffArchiveCtl/delStaffRank";
      return axios.get(url, { params });
    },
    // 职级-查
    getPostApi(params) {
      let url = "/internal/staffArchiveCtl/findStaffRankNose";
      return axios.get(url, { params });
    },
  };
}
