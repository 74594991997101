<!--
自定义下拉框，适用于筛选下拉
使用方法
<Selects
  v-model="search.tab"          //value值
  :list="list"                  //选项[{label:"选项名称",value:"选项值"}]
  placeholder="类型"            //提示
  class="searchTab"             //样式
  @confirm="searchTab"          //返回事件
  @show="getOptFn"              //点击加载显示下拉框时触发
  :isAdd="true"                 //是否存在新增功能，默认false
/>

import Selects from "@/components/Selects.vue"; //引入
components: {Selects}
-->
<template>
  <div ref="select" class="g-select no-select">
    <div
      :class="['title', sty, { on: show }, { isDisabled: disabled }]"
      @click="disabled ? '' : clickFn()"
    >
      <i :class="['prefixIcon', prefixIcon]" v-if="prefixIcon"></i>
      <!-- 前缀插槽，特殊情况用到 -->
      <slot name="prefix" />
      <span :class="['text', { on: label }]" :title="label">{{
        label || placeholder
      }}</span>
      <i :class="['suffixIcon', suffixIcon]"></i>
    </div>
    <div class="option-box" v-show="show">
      <div class="search" v-if="isSearch && list.length > 6">
        <el-input
          type="text"
          autocomplete="off"
          v-model="key"
          class="keywords"
          placeholder="关键词筛选"
          @input="searchFn"
        >
          <em slot="append" class="iconfont iconsousuo" @click="searchFn"></em>
        </el-input>
      </div>
      <ul class="option scroll">
        <li
          v-for="(SelItem, SelIndex) in opt"
          :key="'selectCustom_' + SelIndex"
          @click="selectFn(SelItem)"
          :class="{ on: value == SelItem.value }"
        >
          {{ SelItem.label }}
        </li>
      </ul>
      <div class="add" v-if="isAdd">
        <span class="btn" @click="$emit('add')" v-if="addType == 'out'">
          <em class="iconfont iconjia"></em>
          <span>新增</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-select",
  props: {
    id: Number,
    value: [String, Number],
    placeholder: String,
    list: {
      type: Array,
      default: () => [],
    }, //格式[{label:'名称',value:'值'}]
    sty: { type: String, default: "font" }, //样式选项：font文字样式；select下拉框样式
    prefixIcon: String, //前缀图标,默认不显示
    suffixIcon: { type: String, default: "el-icon-arrow-down" }, //后缀图标,默认下拉箭头

    width: {
      type: String,
      default: "100px",
    },
    height: {
      type: String,
      default: "38px",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSearch: { type: Boolean, default: false },
    defaultText: String, //值：不限、全部等，未选择时默认的空选项，同时作为判断条件是否存在此类空选项
    isAdd: { type: Boolean, default: false }, //是否含新增功能
    addType: { type: String, default: "out" }, //存在新增时，新增表单方式[out,in]：out（默认）外部新增，只需返回父组件一个方法，in（预留尚未开发）内部新增，需要再传一个插槽表单
  },
  data() {
    return {
      show: false,
      label: "",
      opt: [],
      key: "",
    };
  },
  created() {
    this.setValue();
  },
  methods: {
    setListFn() {
      this.opt = [];
      if (this.list.length && typeof this.list[0] == "object") {
        this.opt = JSON.parse(JSON.stringify(this.list));
      } else {
        this.list.forEach((e) => {
          this.opt.push({ label: e, value: e });
        });
      }
      if (this.defaultText) {
        this.opt.unshift({ label: this.defaultText, value: null });
      }
    },
    setValue() {
      this.setListFn();
      if (this.value) {
        let i = this.opt.findIndex((e) => e.value == this.value);
        let label = "";
        if (i > -1) {
          label = this.opt.find((e) => {
            return e.value == this.value;
          }).label;
        }
        this.$set(this, "label", label);
      }
    },
    selectFn(item) {
      this.label = item.value != null ? item.label : "";
      this.$emit("input", item.value);
      this.show = false;
      this.key = "";
      this.setListFn();
      this.$emit("confirm", item);
    },
    clickFn() {
      this.$emit("show");
      this.show = !this.show;
    },
    clear() {
      this.label = "";
      this.$emit("input", "");
    },

    searchFn() {
      this.opt = this.list.filter((e) => e.label.indexOf(this.key) > -1);
      if (this.defaultText) {
        this.opt.unshift({ label: this.defaultText, value: null });
      }
    },

    bodyCloseMenus(e) {
      let self = this;
      if (this.$refs.select && !this.$refs.select.contains(e.target)) {
        if (self.bankSwitch == true) {
          self.bankSwitch = false;
        } else {
          this.show = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
};
</script>

<style lang="less" scoped>
.g-select {
  position: relative;
  border-radius: 2px;
  line-height: 1;
  .title {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &.select {
      padding: 0 10px;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      background-color: #fff;
    }
    &.isDisabled {
      cursor: no-drop;
      background-color: #f5f7fa;
    }
    .text {
      color: #999;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.on {
        color: #606266;
      }
    }
    .prefixIcon {
      margin-right: 5px;
      color: inherit;
    }
    .suffixIcon {
      position: relative;
      color: #c0c4cc;
      margin-left: 5px;
      font-size: 13px;
      // transform: translateY(-50%);
    }
    &.on {
      .suffixIcon {
        transform: rotate(180deg);
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: #e0e0e0;
        z-index: 1984;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: #fff;
        z-index: 1985;
      }
    }
  }
  .option-box {
    position: absolute;
    top: calc(100% + 10px);
    left: -1px;
    min-width: calc(100% + 2px);
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px 0 0px;
    z-index: 1983;
    .search {
      padding: 5px 10px;
      /deep/.keywords {
        min-width: 180px;
        width: 100%;
        height: 28px !important;
        border: 1px solid #e4e4e4 !important;
        border-radius: 2px;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        .el-input__inner {
          flex: 1;
          height: 100%;
          border: 0px;
          font-size: 12px;
        }
        .el-input-group__append {
          border-width: 0px;
          height: 100%;
          padding: 0 15px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .option {
      max-height: 270px;
      overflow: hidden;
      overflow-y: auto;
      li {
        height: 34px;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 12px;
        padding: 0 15px;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          background-color: #f5f7fa;
        }
        &.on {
          color: #39c;
        }
      }
    }
    .add {
      border-top: 1px solid #e4e4e4;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        height: 36px;
        cursor: pointer;
        font-size: 13px;
        color: #999;
        em {
          font-size: 13px;
        }
        &:hover {
          background-color: #f8f8f8;
          color: #39c;
          em {
            color: #39c;
          }
        }
      }
    }
  }
}
</style>
