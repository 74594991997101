<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  created() {
    this.initFn();
  },
  methods: {
    ...mapActions("user", ["login"]),
    ...mapActions("params", ["params"]),
    initFn() {
      // 获取平台信息
      let host = window.location.host;
      let params = { serverName: host };
      this.$api.getPlatInfo(params).then((res) => {
        if (res.code == "200") {
          if (res.result?.system) {
            this.login({
              system: res.result.system,
              systemId: res.result.systemId,
            });
          }
          console.log(res, "=获取平台信息");
        }
      });
      // 初始加载类目参数
      this.$api.getPullList("tStudy").then((res) => {
        if (res.code === "200") {
          let arr = res.data.result;
          let types = [];
          arr
            .filter((e) => e.parent_id == 1)
            .forEach((e) => {
              e.child = arr.filter((x) => x.parent_id == e.value);
              types.push(e);
            });
          types;
          this.params({ goodsType: types });
        }
      });
    },
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
}
.oneEllipsis {
  /* 单行省略，超出用...表示 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w150 {
  width: 150px !important;
}
.w200 {
  width: 200px !important;
}
.w300 {
  width: 300px !important;
}
.w350 {
  width: 350px !important;
}
.w400 {
  width: 400px !important;
}
.w450 {
  width: 450px !important;
}
</style>
