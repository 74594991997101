export default {
  routers: [
    // 404
    {
      path: "*",
      name: "404",
      component: () => import("@/views/error/NotFound.vue"),
    },
    // 首页
    {
      path: "/",
      name: "index",
      component: () => import("@/views/home/indexView.vue"),
    },
    // 首页
    {
      path: "/indexB",
      name: "indexB",
      component: () => import("@/views/home/indexView.vue"),
    },
    // 登录页
    {
      path: "/tob/login",
      name: "loginB",
      component: () => import("@/views/home/loginView.vue"),
    },
    // C端登录页
    {
      path: "/toc/login",
      name: "loginC",
      component: () => import("@/views/home/loginView.vue"),
    },

    {
      //注册模块
      path: "/toc/Register",
      name: "Register",
      component: () => import("@/views/home/regView.vue"),
    },
    {
      //重置密码
      path: "/toc/ResetPsd",
      name: "ResetPsd",
      component: () => import("@/views/home/resetPsd.vue"),
    },

    // 申请试用
    {
      path: "/try",
      name: "try",
      component: () =>
        import(/* webpackChunkName: "try" */ "@/views/home/TryView.vue"),
    },
    // 关于我们
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/home/AboutView.vue"),
    },
    // 渠道合作
    {
      path: "/cooperation",
      name: "cooperation",
      component: () =>
        import(
          /* webpackChunkName: "cooperation" */ "@/views/home/CooperAtion.vue"
        ),
    },
    // 产品-阿仕图-考试
    {
      path: "/project/asktrue/ks",
      name: "project_asktrue1",
      component: () => import("@/views/home/ProjectAsktrue1.vue"),
    },
    // 产品-阿仕图-调查
    {
      path: "/project/asktrue/dc",
      name: "project_asktrue2",
      component: () => import("@/views/home/ProjectAsktrue2.vue"),
    },
    // 产品-阿仕图-评估
    {
      path: "/project/asktrue/pg",
      name: "project_asktrue3",
      component: () => import("@/views/home/ProjectAsktrue3.vue"),
    },
    // 产品-阿仕图-报名
    {
      path: "/project/asktrue/bm",
      name: "project_asktrue4",
      component: () => import("@/views/home/ProjectAsktrue4.vue"),
    },
    // 产品-阿仕图-投票
    {
      path: "/project/asktrue/tp",
      name: "project_asktrue5",
      component: () => import("@/views/home/ProjectAsktrue5.vue"),
    },

    // 控制台
    {
      path: "/control",
      name: "control",
      component: () => import("@/views/manage/indexView.vue"),
    },
    // 培训
    {
      path: "/train",
      name: "train",
      component: () => import("@/views/manage/indexView.vue"),
    },

    // 直播
    // {
    //   path: "/livePlayer",
    //   name: "livePlayer",
    //   component: () => import("@/views/train/live/videoPlayer"),
    // },

    // 会员中心
    {
      path: "/member",
      name: "Member",
      component: () => import("@/views/manage/indexView.vue"),
    },


    // 超管页面
    {
      path: "/admin/",
      name: "admin",
      component: () => import("@/views/admin/routerView.vue"),
    },

  ],
};
