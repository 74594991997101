<template>
  <div class="header-wapper">
    <header>
      <div class="head-logo"><img :src="logo" /></div>
      <div class="login">
        <router-link to="/tob/login">登录</router-link>
        <router-link to="/main">控制台</router-link>
        <router-link to="/train/index">培训</router-link>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "g-header",
  data() {
    return {
      logo: "/static/logo/logo3.png", //logo
    };
  },
};
</script>
<style lang="less" scoped>
header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 64px;
  box-shadow: inset 0 -1px #d7d8d9;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .head-logo {
    padding: 0 25px;
    img {
      height: 25px;
    }
  }
}
</style>
