<!-- 省略号文本，可单行，也可多行，自动加省略号
  采用Paragraph展示方式可参考网址：https://blog.csdn.net/qq_41887214/article/details/116663975
  之所以取消Paragraph展示方式是因为提示信息，宽度没法控制
  
    text: String, //文本内容
    rows: { type: Number, default: 1 }, //行数
    format: { type: Boolean, default: false },//格式化，清除掉dom元素

    调用方式：
    <g-font :text="文本变量" />
    <g-font :text="文本变量" :rows="2" />
    <g-font :text="文本变量" :rows="2" format />
-->
<template>
  <div
    ref="gfont"
    :class="['g-font', { hover }, rows > 1 ? 'rows' : 'row']"
    :title="isEllipsis ? cleanHtml : ''"
    v-html="cleanHtml"
  ></div>

  <!-- <Paragraph
    :content="text"
    :max-lines="rows"
    type="tooltip"
    content-width="300px"
    popper-class="aaaaaaaaa"
  /> -->
</template>

<script>
export default {
  name: "g-font",
  props: {
    text: String, //文本内容
    rows: { type: Number, default: 1 }, //行数
    format: { type: Boolean, default: false },
    hover: { type: Boolean, default: false }, //是否存在鼠标滑过样式
  },
  data() {
    return {
      isEllipsis: false,
    };
  },
  computed: {
    cleanHtml() {
      let content = this.text;
      if (this.format) {
        // 使用DOMParser和textContent来清除HTML标签
        const doc = new DOMParser().parseFromString(content, "text/html");
        content = doc.body.textContent || "";
      }
      return content;
    },
  },

  mounted() {
    this.checkEllipsis();
  },

  methods: {
    checkEllipsis() {
      this.$nextTick(() => {
        const element = this.$refs.gfont;
        const computedStyle = window.getComputedStyle(element);
        const width = element.clientWidth;
        const height = element.clientHeight;
        const textWidth = element.scrollWidth;
        const textHeight = element.scrollHeight;
        const overflow = computedStyle.getPropertyValue("overflow");

        this.isEllipsis =
          (width < textWidth || height < textHeight) &&
          overflow.includes("hidden");
      });
    },
  },
};
</script>

<style lang="less">
.g-font {
  overflow: hidden;
  text-overflow: ellipsis;
  &.row {
    white-space: nowrap;
  }
  &.rows {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-all;
    -webkit-line-clamp: v-bind("rows");
  }

  font-size: inherit;
  color: inherit;
  &.hover:hover {
    cursor: pointer;
    color: #39c;
  }
}
// .el-popper.vue-paragraph-popover-dark {
//   border-width: 0px !important;
// }
</style>
